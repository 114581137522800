.sitemap {
    background-color: #fff;
    padding: 47px 34.3px 45px 20.5px;
    border-left: 2px solid #ffe100;

    @include mq("phablet") {
        padding: 47px 34.3px 45px 100.5px;
    }

    @include mq("tablet") {
        padding: 78px 70px;
    }

    @include mq("bootstrap-lg") {
        padding: 75px 100px;
    }

    @include mq("desktop") {
        padding: 67px 133px 90px 173px;
    }

    &-wrapper {
        background-image: url(".././img/common/dotted-bg.png");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        padding-bottom: 65px;

        @media (min-width: 768px) {
            padding-bottom: 144px;
        }
    }

    &-top-txt {
        font-weight: 700;
        letter-spacing: 0.05em;
        position: relative;
        display: inline-block;
        margin-bottom: 4.5px;
        font-size: 2rem;
        line-height: calc(29 / 20);

        @include mq("tablet") {
            line-height: calc(40 / 28);
            font-size: 2.8rem;
            margin-bottom: 28.5px;
        }

        &::after {
            content: "";
            background-image: url(".././img/common/arw-black.svg");
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            width: 14px;
            height: 11px;
            display: block;
            position: absolute;
            right: -26px;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    &-box {
        position: relative;
        padding-left: 46px;
        margin-top: 50px;

        @include mq("tablet") {
            margin-top: 82px;
            padding-left: 125px;
            margin-left: 60px;
        }

        @include mq("bootstrap-lg") {
            margin-left: 73px;
        }

        &:last-of-type {
            &::before {
                content: none;
            }
        }

        &.extra {
            margin-top: 52px;

            @include mq("tablet") {
                margin-top: 100px;
            }

            // &::before {
            //     @include mq("tablet") {
            //         height: calc(100% + 44px - 10px);
            //         bottom: -60px;
            //     }
            // }

            &::after {
                content: "";
                position: absolute;
                left: 0;
                border-left: 1px solid #707070;
                border-bottom: 1px solid #707070;
                width: 38.91px;
                height: 60.17px;
                border-bottom-left-radius: 10px;
                top: -50px;

                @include mq("tablet") {
                    top: -103px;
                    border-bottom-left-radius: 20px;
                    height: 123px;
                    width: 80px;
                }
            }
        }

        &.double-extra {
            @include mq("tablet") {
                margin-top: 38px;
            }
        }

        &::before {
            content: "";
            position: absolute;
            left: 0;
            border-left: 1px solid #707070;
            border-bottom: 1px solid #707070;
            width: 38px;
            height: calc(100% + 50px - 10px);
            border-bottom-left-radius: 10px;
            bottom: -67px;

            @include mq("tablet") {
                border-bottom-left-radius: 15px;
                bottom: -105px;
                width: 80px;
                height: calc(100% + 60px - 10px);
            }

            @include mq("bootstrap-lg") {
                height: calc(100% + 82px - 10px);
            }
        }

        &.-btm {
            margin-top: 46px;
            @include mq("tablet") {
                margin-top: 54.5px;
            }
            
            &.-diff{
                margin-top: 50px;
                @include mq("tablet") {
                    margin-top: 82px;
                }
            }
            &::before{
                content: none;
            }
            &:last-child{
                &::after{
                    content: none;
                }
            }

            &::after {
                content: "";
                position: absolute;
                left: 0;
                border-left: 1px solid #707070;
                border-bottom: 1px solid #707070;
                width: 38px;
                height: calc(100% + 46px - 10px);
                border-bottom-left-radius: 10px;
                top: 24px;
                @include mq("tablet") {
                    width: 80px;
                    height: calc(100% + 54.5px - 10px);
                    top: 38px;
                }
            }

            .sitemap-ttl {
                width: fit-content;
                position: relative;

                &::after {
                    content: '';
                    position: absolute;
                    background: url(../img/common/arw-black.svg) no-repeat;
                    background-position: center;
                    background-size: cover;
                    width: 10.5px;
                    height: 7.88px;
                    top: 50%;
                    transform: translateY(-50%);
                    right: -19px;
                }
            }
        }
    }

    &-ttl {
        font-weight: 400;
        letter-spacing: 0.05em;
        font-size: 2rem;
        line-height: calc(29 / 20);
        margin-bottom: 22px;

        @include mq("tablet") {
            margin-bottom: 20px;
            line-height: calc(40 / 28);
            font-size: 2.5rem;
        }

        @include mq("bootstrap-lg") {
            font-size: 2.8rem;
        }
    }

    &-list {
        display: flex;
        flex-wrap: wrap;
        gap: 20px 30px;

        &.first {
            max-width: 312px;
        }

        &-container {
            display: flex;
            align-items: flex-start;
            max-width: 593px;
            flex-direction: column;
            gap: 14px;

            @include mq("bootstrap-lg") {
                gap: 30px;
                flex-direction: row;
            }

            .sitemap-list {
                @include mq("bootstrap-lg", "max") {
                    gap: 14px;
                }
            }

            &.second {
                max-width: 227px;

                .sitemap-list {
                    @include mq("tablet", "max") {
                        gap: 20px;
                    }

                    .sitemap-list-item {
                        gap: 47px;
                        width: 100%;

                        @include mq("bootstrap-lg") {
                            width: calc(100% / 2 - 15px);
                        }
                    }
                }
            }

            &.third {
                max-width: 270px;

                .sitemap-list {
                    @include mq("tablet", "max") {
                        gap: 20px;
                        flex-direction: column;
                    }

                    @include mq("tablet") {
                        width: 100%;
                    }

                    &-item {
                        @include mq("tablet") {
                            width: fit-content;
                        }
                    }

                    display: flex;
                    align-items: center;
                    gap: 47px;
                    flex-wrap: nowrap;
                }

                .sitemap-list-container {
                    width: 100%;
                }
            }

            &.fourth {
                max-width: 433px;
                gap: 0;

                .sitemap-list {
                    display: flex;
                    align-items: center;
                    flex-wrap: nowrap;
                    flex-direction: column;
                    gap: 20px;
                    flex: 1;

                    @include mq("bootstrap-lg") {
                        gap: 30px;
                        flex-direction: row;
                    }
                }
            }

            &.fifth {
                max-width: 468px;

                .sitemap-list {
                    gap: 20px 46px;

                    &.first {
                        max-width: 234px;
                    }
                }
            }

            &.sixth {
                max-width: 555px;

                .sitemap-list {
                    gap: 20px;
                    flex: 1;

                    @include mq("bootstrap-lg") {
                        gap: 30px;
                        flex-wrap: nowrap;
                        flex-direction: row;
                    }

                    &-item {
                        &:nth-of-type(2) {
                            @include mq("bootstrap-lg") {
                                max-width: 122px;
                            }
                        }
                    }
                }
            }
        }

        &-item {
            font-size: 1.6rem;
            letter-spacing: 0;
            font-weight: 400;
            width: 100%;
            line-height: calc(24 / 16);
            cursor: pointer;

            @include mq("tablet") {
                line-height: calc(32 / 16);
                width: 100%;
            }

            &:hover {
                text-decoration: underline;
            }

            a {
                color: $color-primary;
                text-decoration: none;
                position: relative;

                &::after {
                    content: "";
                    background-image: url(".././img/common/arw-black.svg");
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;
                    width: 11px;
                    height: 8px;
                    position: absolute;
                    right: -18px;
                    display: block;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }

            .space {
                &::after {
                    content: none;
                }
            }

            &.double {
                @include mq("phablet", "max") {
                    a {
                        &::after {
                            top: 26%;
                            right: -62px;
                        }
                    }
                }
            }

            &.double-x {
                @include mq("phablet", "max") {
                    a {
                        &::after {
                            top: 26%;
                            right: -26px;
                        }
                    }
                }
            }

            &.box {
                a {
                    &::after {
                        background-image: url(".././img/common/box-black.svg");
                        width: 10px;
                        height: 10px;
                    }
                }
            }
        }
    }
}