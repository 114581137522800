.pagination {
    &__list {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__item {
        background-color: $color-white;
        height: 40px;
        min-width: 38px;
        border-radius: 1000px;

        &:not(:last-of-type) {
            margin-right: 15px;
        }

        a {
            font-weight: 700;
            color: #444444;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            padding: 7px;
            transition: all 0.25s ease;
            font-size: 1.8rem;
            letter-spacing: .05em;

            &:hover {
                opacity: 0.7;
            }
        }

        &.-active {
            color: $color-black;
            background-color: transparent;
            border-bottom: 3px solid $color-black;
            border-radius: 0;
            a {
                color: $color-black;
            }
        }

        &.-prev,
        &.-next {
            a {
                position: relative;
                width: auto;

                &::after {
                    content: "";
                    background-image: url(../img/pagination-arrow.svg);
                    background-repeat: no-repeat;
                    background-size: contain;
                    height: 6px;
                    width: 8px;
                    position: absolute;
                }
            }
        }

        &.-prev {
            display: none;
        }

        &.-next {
            min-width: 30px;
            height: 32px;

            img {
                width: 6px;
                height: 11px;
            }
        }
    }
}
