.before2 {
    .pagetitlebar__img {
        display: none;
    }

    input[type="checkbox" i] {
        margin: 0;
    }

    &-top {
        background-image: url(".././img/common/dotted-bg.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    .pagetitlebar__heading {
        padding-block: 40px 30px;
        @media (min-width: 768px) {
            padding-block: 50px 68px;
        }
    }
}

.regarding {
    padding-block: 70px;

    @media (min-width: 768px) {
        padding-block: 41px 60.5px;
    }

    @media (min-width: 992px) {
        padding-block: 100px 125px;
    }

    .primary-heading {
        @include mq("tablet", "max") {
            margin-bottom: 30px;
        }
    }

    &-box {
        background: url(.././img/common/dotted-bg.png) no-repeat;
        background-position: center;
        background-size: cover;
        padding: 40px 18px 29px 19.5px;

        @media (min-width: 768px) {
            padding: 60px 0;
        }

        &-top-ttl {
            font-weight: 400;
            letter-spacing: 0.08em;
            border-bottom: 1px solid rgba(34, 34, 34, 0.5);
            margin-inline: 0;
            text-align: start;
            padding-bottom: 20px;
            font-size: 18px;
            line-height: 35.6px;

            @media (min-width: 768px) {
                letter-spacing: 0.1em;
                padding-bottom: 41px;
                text-align: center;
                margin-inline: 61.5px 60.5px;
                font-size: 21px;
                line-height: 36.67px;
            }

            @media (min-width: 992px) {
                font-size: 24px;
                line-height: 43.67px;
            }
        }

        &-mid-ttl {
            font-weight: 700;
            letter-spacing: 0.05em;
            padding-block: 20px 30px;
            font-size: 16px;
            line-height: 27px;
            text-align: center;

            @media (min-width: 768px) {
                line-height: 28px;
                font-size: 16px;
                padding-block: 40px;
            }
        }

        &-points {
            margin-bottom: 30px;

            @media (min-width: 768px) {
                margin-bottom: 60px;
                padding-inline: 60px;
            }

            @media (min-width: 992px) {
                padding-inline: 185px 184px;
            }

            &-txt {
                display: block;
                align-content: flex-start;
                gap: 5px;
                font-size: 16px;
                line-height: 25.6px;

                @media (min-width: 768px) {
                    display: flex;
                    letter-spacing: 0.05em;
                    line-height: 28px;
                }

                &.first {
                    margin-bottom: 15px;
                    @media (min-width: 768px) {
                        margin-bottom: 22px;
                    }
                }

                span {
                    flex-shrink: 0;
                }
            }
        }

        &-mid-btm-txt {
            text-align: center;
            letter-spacing: 0.05em;
            font-size: 16px;
            font-weight: 400;
            margin-bottom: 32px;
            line-height: 29.3px;

            @media (min-width: 768px) {
                line-height: 27.5px;
                margin-bottom: 29px;
            }
        }

        &-btm {
            background-color: #ffffff;
            padding: 15px 0 16px;

            @media (min-width: 768px) {
                margin-inline: 60px;
                padding: 40px 20px;
            }

            @media (min-width: 992px) {
                padding: 40px 0;
                margin-inline: 100px;
            }

            &-row {
                max-width: 720px;
                display: flex;
                align-items: stretch;
                flex-wrap: wrap;
                gap: 10px;
                margin: 23px 17.5px 0 18.5px;

                @media (min-width: 768px) {
                    margin: 28px auto 0;
                    gap: 20px;
                }
            }

            &-col {
                text-align: center;
                border: 1.5px solid #909090;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                padding: 12.5px 0;

                @media (min-width: 768px) {
                    padding: 19px 0 29px;
                    width: calc(100% / 2 - 10px);
                }
                &:last-child{
                    padding: 15px 0;

                    @media (min-width: 768px) {
                        padding: 19px 0 29px;
                    }
                }
            }

            &-ttl {
                text-align: center;
                font-weight: 400;
                letter-spacing: 0.1em;
                font-size: 18px;
                line-height: 29px;
                margin-bottom: 23px;

                @media (min-width: 768px) {
                    font-size: 24px;
                    line-height: 35px;
                    margin-bottom: 11px;
                }
            }

            &-sub {
                font-weight: 400;
                font-size: 16px;
                letter-spacing: 0.05em;
                text-align: center;
                line-height: 27px;

                @media (min-width: 768px) {
                    line-height: 24px;
                }
            }

            &-head {
                letter-spacing: 0.1em;
                font-weight: 400;
                font-size: 18px;
                line-height: 26px;

                @media (min-width: 768px) {
                    font-size: 24px;
                    line-height: 35px;
                }
            }

            &-sm {
                font-size: 13px;
                letter-spacing: 0.05em;
                line-height: 19px;
                font-weight: 400;
                margin-top: 8px;

                @media (min-width: 768px) {
                    margin-top: 6px;
                }
            }
        }
    }

    &-btn {
        margin: 0 auto 30px;
        max-width: 100%;

        @media (min-width: 768px) {
            margin: 0 auto 60px;
            max-width: 600px;
        }

        @media (min-width: 992px) {
            max-width: 700px;
        }
    }

    &-btns {
        font-weight: 700;
        letter-spacing: 0.05em;
        background-color: #222222;
        color: #ffffff;
        position: relative;
        border: 1px solid #222222;
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        transition: all 0.3s ease;
        padding: 7px 0 8px;
        font-size: 16px;
        line-height: 25px;
        max-width: 295px;

        @media (min-width: 768px) {
            max-width: 100%;
            font-size: 18px;
            line-height: 26px;
            padding: 19px 0 20px;
        }

        &::after {
            transition: all 0.3s ease;
            content: "";
            background-image: url(".././img/common/reg-right-arrow.svg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            position: absolute;
            width: 10px;
            height: 12px;
            top: 50%;
            transform: translateY(-50%);
            right: 15px;

            @media (min-width: 768px) {
                right: 20px;
            }
        }

        &:hover {
            background-color: $color-yellow;
            color: #222222;
            border: 1px solid $color-yellow;

            &::after {
                background-image: url(".././img/common/reg-right-arrow-hover.svg");
            }
        }
    }

    &-mid-txt {
        font-weight: 400;
        letter-spacing: 0.1em;
        margin-top: 60px;
        font-size: 16px;
        line-height: 25.6px;
        text-align: center;

        @media (min-width: 768px) {
            text-align: center;
            margin-top: 83px;
            font-size: 18px;
            line-height: 33px;
        }

        @media (min-width: 992px) {
            font-size: 24px;
            line-height: 43px;
        }
    }

    &-form {
        &-container {
            margin: 40.5px 0 0;
            @media (min-width: 768px) {
                margin: 61px 0 0;
            }
        }

        &-wrapper {
            display: flex;
            justify-content: center;
        }
    }

    &-form-row {
        &.mt {
            margin-top: 14px;

            @media (min-width: 768px) {
                margin-top: 28px;
            }
        }

        label {
            font-weight: 700;
            display: flex;
            align-items: flex-end;
            font-size: 16px;
            letter-spacing: 0.1em;
            line-height: 24px;
            cursor: pointer;

            @media (min-width: 768px) {
                font-size: 24px;
                line-height: 30px;
                margin-top: 5px;
            }
        }

        input {
            cursor: pointer;
            width: 21px;
            height: 21px;
            border-radius: 3px;
            margin-right: 11px !important;

            @media (min-width: 768px) {
                width: 30px;
                margin-right: 15px !important;
                height: 30px;
            }
        }
    }

    &-form-btn {
        font-weight: 700;
        letter-spacing: 0.05em;
        background-color: #ffe100;
        position: relative;
        border: 1px solid #ffe100;
        margin: 30px auto 0;
        text-align: center;
        display: flex;
        justify-content: center;
        transition: all 0.3s ease;
        padding: 12px 0 12px;
        font-size: 16px;
        line-height: 25px;
        width: 100%;
        cursor: pointer;
        font-family: $font-primary;

        @media (min-width: 768px) {
            width: 700px;
            font-size: 24px;
            line-height: 35px;
            padding: 19px 0 20px;
            margin-top: 41px;
        }

        &::after {
            content: "";
            background-image: url(".././img/common/reg-right-arrow-hover.svg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            position: absolute;
            width: 10px;
            height: 12px;
            top: 50%;
            transform: translateY(-50%);
            right: 15px;

            @media (min-width: 768px) {
                right: 20px;
            }
        }

        &:hover {
            background-color: #222;
            border: 1px solid #222;
            color: #fff;

            &::after {
                background-image: url("../img/common/reg-right-arrow.svg");
            }
        }
    }
}

.contact {
    &-steps {
        &-row {
            display: flex !important;
            flex-wrap: wrap;
            align-items: stretch;
            gap: 9px;
            padding-bottom: 59px;

            @media (min-width: 768px) {
                gap: 20px;
                padding-bottom: 85px;
            }
        }

        &-item {
            background: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            width: calc((100% / 2) - (9px / 2));
            border: 5px solid #909090;
            min-height: 84px;

            @media (min-width: 768px) {
                position: relative;
                min-height: 100%;
                border: 7px solid #909090;
                width: calc((100% / 4) - ((20px * 3) / 4));
            }

            &.active {
                border-color: #ffe100;

                .contact-steps-link {
                    color: #000;
                }
            }

            &::after {
                content: "";
                width: 20px;
                height: 1px;
                background: #000000;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: -27px;
            }

            @media (max-width: 767px) {
                &::after {
                    content: none;
                }
            }

            &:last-of-type {
                &::after {
                    content: none;
                }
            }
        }

        &-link {
            color: #909090;
            width: 100%;
            letter-spacing: 0.05em;
            font-weight: 500;
            font-size: 1.4rem;
            line-height: 20px;
            padding: 6px 0;

            @media (min-width: 768px) {
                padding: 10px 0;
                line-height: 23px;
                font-size: 1.3rem;
            }

            @media (min-width: 992px) {
                line-height: 30.5px;
                font-size: 1.6rem;
            }

            @media (min-width: 1200px) {
                font-size: 2rem;
            }
        }
    }
}