header {
    // position: relative;
    // display: flex !important;

    .header {
        &-top {
            pointer-events:auto;
            width: 100%;
            position: fixed;
            background: transparent;
            display: flex;
            align-items: center;
            justify-content: space-between;
            z-index: 10;

            padding-inline: 9px 15px;
            transition: all 0.5s ease;
            

            @include mq("tablet") {
                padding-inline: 5px;
                pointer-events: auto;
            }

            @include mq("bootstrap-lg") {
                padding-inline: 10px;
            }

            @include mq("desktop-lg") {
                padding-inline: 15px 30px;
            }

            &.add-bg {
                background: $color-white;

                .nav-list--link {
                    color: $color-primary;

                    &.-down {
                        &::after {
                            border-top: 5px solid $color-primary;
                        }
                    }
                }
            }
            &.display-f{
                display: flex !important;
            }

            &:hover {
                background: transparent;
                @include mq("tablet") {
                    background: $color-white;
                }

                .nav-list--link {
                    color: $color-primary;

                    &.-down {
                        &::after {
                            border-top: 5px solid $color-primary;
                        }
                    }
                }
            }
        }

        &-block {
            &--logo {
                background: $color-white;
                padding: 7.5px 15.2px 8px 10.9px;
                margin-top: 10px;
                border-radius: 100px;

                @include mq("tablet") {
                    padding: 6px 8px;
                    margin-top: 0;
                }

                @include mq("bootstrap-lg") {
                    padding: 8px;
                }

                @include mq("desktop") {
                    padding: 10px 17.5px 9.3px 16px;
                }

                img {
                    width: 100%;
                    max-width: 199px;

                    @include mq("tablet") {
                        max-width: 120px;
                    }

                    @include mq("bootstrap-lg") {
                        max-width: 155px;
                    }

                    @include mq("desktop") {
                        max-width: 200px;
                    }

                    @include mq("desktop-lg") {
                        max-width: 100%;
                    }
                }
            }
        }

        &-btn {
            transition: all .5s ease;
            display: none;
            background: $color-primary;
            color: $color-white;
            border-radius: 22px;
            align-items: center;
            gap: 7px;
            font-size: 1.4rem;
            line-height: calc(21 / 14);
            letter-spacing: .05em;
            padding: 10px 15px;
            margin-block: 18px 17px;
            margin-left: 0;

            @include mq("tablet") {
                display: flex;
                padding: 10px 5px;
                font-size: 1rem;
                line-height: calc(18 / 10);
            }

            @include mq("bootstrap-lg") {
                padding: 10px 15px;
                font-size: 1.4rem;
                line-height: calc(21 / 14);
            }

            @include mq("desktop") {
                font-size: 1.5rem;
                line-height: calc(21 / 15);
            }

            @include mq("desktop-lg") {
                padding: 10px 19px 13px 14px;
                margin-left: -64px;
            }

            .norm {
                opacity: 1;
                transition: all .5s ease;
                position: relative;
            }

            .hover {
                opacity: 0;
                transition: all .5s ease;
                position: absolute;
            }

            &:hover {
                background: $color-yellow;
                color: $color-primary;

                .norm {
                    opacity: 0;
                    position: absolute;
                }

                .hover {
                    opacity: 1;
                    position: relative;
                }
            }

            img {
                width: 15px;

                @include mq("desktop") {
                    width: 20px;
                }
            }
        }

        &-btm {
            opacity: 1;

            @include mq("tablet") {
                opacity: 0;
            }

            &-block {
                width: 100%;
                background: rgba(255, 255, 255, .95);
                position: fixed;
                bottom: 0;
                left: 0;
                // padding-block: 10px 6px;
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 20;

            }

            &-btn {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 11px;
                padding-inline: 19px 11px;

                &:hover {
                    opacity: .7;

                    .arrow-icon {
                        background: rgba(0, 0, 0, .2);
                    }
                }

                .arrow {
                    width: 6px;
                    height: 7px;

                    &-icon {
                        transition: all .5s ease;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 50%;
                        width: 20px;
                        height: 20px;
                        border: 1px solid rgba(0, 0, 0, .2);
                    }
                }
            }

            &-top {
                width: 100%;
                transition: all .5s ease;
                color: $color-primary;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 5px;
                padding-inline: 15px;
                padding-block: 10px 9px;
                position: relative;

                &::before,
                &::after {
                    position: absolute;
                    content: '';
                    background: rgba(0, 0, 0, .2);
                    width: 1px;
                    height: 50px;
                    top: 0;
                }

                &::before {
                    left: 0;
                }

                &::after {
                    right: 0;
                }

                img {
                    width: 15px;
                    height: 13px;
                }

                span {
                    font-size: .9rem;
                    line-height: calc(13 / 9);
                    letter-spacing: .1em;
                }

                &:hover {
                    opacity: .7;
                }
            }
        }
    }

    .nav {
        &-list {
            display: none;
            gap: 15px;

            @include mq("tablet") {
                display: flex;
                align-items: center;
                gap: 8px;
            }

            @include mq("bootstrap-lg") {
                gap: 15px;
            }

            @include mq("desktop-lg") {
                gap: 28px;
            }

            .active {
                // padding: 27px 0 31px;
                background: $color-yellow;
            }

            &--item {
                cursor: pointer;
                padding: 27px 0 31px;
                transition: all .5s ease-in;

                &:hover {
                    // padding: 27px 0 31px;
                    background: $color-yellow;
                    // background-position: left bottom;
                }
            }

            .drop-down {
                transition: all .5s ease;

                // .nav-list--link {
                //     background: none;
                //     background-size: none;
                // }
            }

            .drop-down:hover {

                .category-content {
                    opacity: 1;
                    visibility: visible;
                    max-height: 100%;
                    // display: block;
                }
            }

            &--link {
                cursor: pointer;
                transition: all .5s;
                color: $color-white;
                font-weight: 500;
                font-size: 1.4rem;
                line-height: calc(21 / 14);
                letter-spacing: .05em;
                padding-inline: 2px;

                // background: linear-gradient(to right, $color-yellow 50%, transparent 50%);
                // background-size: 205% 100%;
                // background-position: right bottom;
                transition: all .5s ease-out;

                @include mq("tablet") {
                    font-size: 1.2rem;
                    line-height: calc(18 / 12);
                }

                @include mq("bootstrap-lg") {
                    font-size: 1.4rem;
                    line-height: calc(21 / 14);
                }

                @include mq("desktop") {
                    font-size: 1.5rem;
                    line-height: calc(21 / 15);
                    padding-inline: 5px;
                }

                &.-down {
                    display: block;
                    position: relative;

                    &::after {
                        content: '';
                        position: absolute;
                        border-top: 5px solid $color-white;
                        border-left: 5px solid transparent;
                        border-right: 5px solid transparent;
                        bottom: -23px;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                }
            }
        }
    }
}