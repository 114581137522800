.pagetitlebar {
    &__heading {
        text-align: center;
        font-weight: 400;
        padding-block: 34px 24px;
        // padding-block: 90px 24px;


        @include mq("tablet") {
            // padding-block: 165px 49px;
            padding-block: 41px 49px;

        }
    }

    &__img {
        img {
            width: 100%;
            height: 140px;
            border-top: 2px solid $color-yellow;

            @include mq("tablet") {
                height: auto;
            }
        }

    }

    &__title {
        color: rgba($color: #222, $alpha: .56);
        font-family: $font-sec;
        font-size: 1.5rem;
        line-height: calc(20 / 15);
        letter-spacing: .1em;
        padding-bottom: 3px;

        @include mq("tablet") {
            font-size: 2rem;
            line-height: calc(27 / 20);
            padding-bottom: 11px;
        }
    }

    &__subtitle {
        font-size: 3rem;
        line-height: calc(44 / 30);
        letter-spacing: .1em;

        @include mq("tablet") {
            font-size: 5.4rem;
            line-height: calc(79 / 54);
        }
    }

    .display-none {
        display: none;
    }
}