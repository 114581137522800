.category {
    &-content {
        cursor: auto;
        position: fixed;
        width: 100%;
        max-width: 100%;
        left: 0;
        top: 78px;
        background: rgba(0, 0, 0, .3);
        height: 100%;
        transition: visibility 0.5s ease, opacity .5s ease;
        opacity: 0;
        overflow: hidden;
        visibility: hidden;
        pointer-events: none;
        max-height: 0;

    }

    &-block {
        background: rgba(255, 255, 255, .9);
        transition: all 0.5s ease;
        padding-block: 35px 0;
        pointer-events: auto;

        &--heading {
            font-weight: 400;
            text-align: center;
            font-size: 2.8rem;
            line-height: calc(40 / 28);
            letter-spacing: .1em;
        }
    }

    &-row {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 2px;
        padding-block: 32px 39px;

        &.-scd {
            .category-col {
                width: calc((100% / 4) - (2px * 3) / 4);
            }
        }

        &.-trd {
            .category-col {
                width: calc((100% / 2) - 2px);
            }

            padding-bottom: 42px;
        }

        &.-top {
            flex-wrap: nowrap;
            padding-block: 32px 3px;

            .category-col--img img {
                min-height: 160px;
                height: 100%;
            }
        }


        &.-btm {
            flex-wrap: nowrap;
            padding-block: 0 39px;
        }
    }

    &-col {
        width: calc((100% / 3) - (2px * 2) / 3);
        color: $color-white;
        position: relative;

        &:hover {
            .category-col--img {

                &::after {
                    background: rgba(0, 0, 0, .5);
                }
            }

            .category-col--icon {
                background: rgba(0, 0, 0, .40);
                // border: 1px solid $color-white;
            }

            img {
                transform: scale(1.2);
            }

        }

        &:hover {
            .span {
                &::before {
                    border-top-color: $color-white; // Show borders
                    border-right-color: $color-white;
                    border-bottom-color: $color-white;

                    transition:
                        border-top-color 0.15s linear, // Stagger border appearances
                        border-right-color 0.15s linear 0.10s,
                        border-bottom-color 0.15s linear 0.20s;
                }



                &::after {
                    border-top: 2px solid $color-white; // Shows border
                    border-left-width: 2px; // Solid edges, invisible borders
                    border-right-width: 2px; // Solid edges, invisible borders
                    transform: rotate(270deg); // Rotate around circle
                    transition:
                        transform 0.4s linear 0s,
                        border-left-width 0s linear 0.35s; // Solid edge post-rotation        }
                }

            }
        }

        &--img {
            overflow: hidden;

            img {
                transition: all .5s ease;
            }

            position: relative;

            &::after {
                content: '';
                position: absolute;
                // background: rgba(0, 0, 0, .2);
                background: radial-gradient(rgba(0, 0, 0, .2), rgba(0, 0, 0, .65));
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
            }
        }

        &--desc {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%)translateY(-50%);

            p {
                text-align: center;
                font-weight: 500;
                font-size: 2.4rem;
                line-height: calc(35.5 / 24);
                letter-spacing: .1em;

                @include mq("tablet") {
                    font-size: 1.5rem;
                    line-height: calc(28 / 15);
                }

                @include mq("bootstrap-lg") {
                    font-size: 2.4rem;
                    line-height: calc(35.5 / 24);
                }
            }
        }

        &--icon {
            position: absolute;
            bottom: 12px;
            right: 15.3px;
            background: rgba(0, 0, 0, .35);
            // padding: 16.5px 16.5px;
            // border: 1px solid rgba(0, 0, 0, .005);
            border-radius: 50%;

            // @include mq("tablet") {
            //     padding: 12.5px;
            // }

            // @include mq("bootstrap-lg") {
            //     padding: 16.5px 16.5px;
            // }

            .span {
                background: none;
                border: 0;
                box-sizing: border-box;
                padding: 12.5px 13px;
                border: 1px solid $color-white;

                @include mq("tablet") {
                    padding: 12.5px;
                }

                @include mq("bootstrap-lg") {
                    padding: 17.5px 17.5px;
                    border: none;
                }


                position : relative;
                vertical-align: middle;

                &::before,
                &::after {
                    box-sizing: inherit;
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                }

                &::before {
                    border: 2px solid transparent;
                }

                &::after {
                    border: 0 solid transparent;
                }

            }

            .circle {
                border-radius: 100%;
                box-shadow: none;

                &::before,
                &::after {
                    border-radius: 100%;
                }
            }


            img {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 10px;
                height: 10px;
            }
        }
    }
}

.menu {
    cursor: pointer;
    width: 100%;
    transition: all .5s ease;
    color: $color-primary;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
    padding-inline: 11px;
    padding-block: 8px;
    position: relative;

    &-line {
        background: $color-primary;
        display: block;
        width: 19px;
        height: 1px;
        // position: absolute;
        transition: all .5s ease;
        transition: transform .3s;
    }

    span {
        font-size: .9rem;
        line-height: calc(13 / 9);
        letter-spacing: .1em;
    }

    &:hover {
        opacity: .7;
    }

    &-content {
        background: $color-primary;
        color: $color-white;
        position: fixed;
        top: 0;
        width: 100%;
        overflow-y: auto;
        transition: all 0.3s ease-in-out;
        // overflow: hidden;
        opacity: 0;
        max-height: 0;
        height: calc(100% - 50px);
        padding-top: 17px;

        &.open {
            opacity: 1;
            max-height: 100%;
        }
    }

    &-block {

        .close-btn {
            cursor: pointer;
            width: 16px;
            margin-inline: auto 17px;
        }

        .header-btn {
            display: flex;
            border: 1px solid $color-white;
            padding: 12px 16px 13px;
            margin-bottom: 15px;
            margin-inline: 10px;

            &:hover {
                // background: rgba(34, 34, 34, .7);
                opacity: .7;
            }
        }
    }

    &-list {
        &--item {}

        &-content {
            .menu-list--link {
                background: $color-primary;
            }
        }

        &--link {
            cursor: pointer;
            display: block;
            position: relative;
            width: 100%;
            background: #444444;
            color: $color-white;
            border-bottom: 1.5px solid rgba(255, 255, 255, .1);

            font-weight: 700;
            font-size: 1.6rem;
            line-height: calc(24 / 16);
            letter-spacing: .05em;
            padding-block: 14px 16px;
            padding-inline: 20px 21.5px;

            @include mq("tablet") {
                background: transparent;
            }

            &:hover {
                background: rgba(68, 68, 68, .8);
            }

            &.pl {
                padding-left: 39px;
            }

            &.bl-ylw {
                position: relative;

                &::before {
                    content: '';
                    position: absolute;
                    background: #FFE100;
                    width: 4px;
                    height: 18px;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 19px;
                }

            }

            &.aft-arw {
                position: relative;

                &:after {
                    content: '';
                    position: absolute;
                    background: url(../img/common/arw-white.svg) no-repeat;
                    background-position: center;
                    background-size: cover;
                    width: 11px;
                    height: 7px;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 21.5px;
                }

            }

            &.aft-box {
                position: relative;

                &:after {
                    content: '';
                    position: absolute;
                    background: url(../img/common/box.png) no-repeat;
                    background-position: center;
                    background-size: cover;
                    width: 10px;
                    height: 10px;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 22px;
                }

            }

            &.menu-down {
                position: relative;

                &::after {
                    content: '';
                    position: absolute;
                    background: url(../img/common/add.png) no-repeat;
                    background-position: center;
                    background-size: cover;
                    width: 12px;
                    height: 12px;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 20px;
                }

                &.active::after {
                    background: url(../img/common/minus.png) no-repeat center;
                    background-size: cover;
                    width: 12px;
                    height: 2px;
                }

                &::before {
                    content: '';
                    position: absolute;
                    background: #FFE100;
                    width: 4px;
                    height: 18px;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 19px;
                }

            }
        }
    }
}

.section {
    padding-block: 60px;

    @include mq("tablet") {
        padding-block: 80px;
    }

    @include mq("bootstrap-lg") {
        padding-block: 120px;
    }

}

.primary-heading {
    position: relative;
    text-align: center;
    font-size: 2rem;
    line-height: calc(29 / 20);
    letter-spacing: .1em;
    padding-bottom: 35px;
    margin-bottom: 20px;

    &::after {
        position: absolute;
        content: '';
        width: 1px;
        height: 20px;
        background: rgba($color: #222, $alpha: .4);
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);

        @include mq("tablet") {
            height: 30px;
        }
    }

    @include mq("tablet") {
        font-size: 4rem;
        line-height: calc(58 / 40);
        padding-bottom: 61px;
        margin-bottom: 30px;
    }
}

.c-primary {
    color: $color-primary !important;

    &::after {
        border-top: 5px solid #222 !important;
    }
}

.menu-diff {
    .header-top {
        background: $color-white;
        box-shadow: 0px 7px 16px -18px #222;
        // position: fixed;

        // &::after {
        //     position: absolute;
        //     content: '';
        //     width: 100%;
        //     height: 10px;
        //     background: url(../img/common/line.png) no-repeat;
        //     background-position: center;
        //     background-size: cover;
        //     bottom: -9px;
        //     left: 0;
        // }
    }
    .header-block--logo{
        pointer-events: all;
        padding: 2.5px 15px 11.5px;
        @include mq("tablet"){
            padding: 7.5px 17.2px 7.5px 12px;
        }
    }

    .header-btm-block {
        width: fit-content;
        top: 0;
        bottom: unset;
        right: 0;
        left: unset;
        margin-inline: auto 0;
    }

    .header-btm-btn {
        display: none;
    }

    .header-btm-top {
        display: none;
    }

    .menu {
        // width: 55px;
        // height: 50px;
        background: $color-primary;
        padding: 17px 18px;
        &-content{
            height: 100%;
            left: 0;
        }

        &-line--top,
        &-line--center,
        &-line--bottom {
            background: $color-white;
        }

        &-line--menu {
            display: none;
        }
    }
}

.d-n,
.d-n-xs {
    display: none !important;
}

.d-i,
.d-i-xs {
    display: inline !important;
}

.d-b,
.d-b-xs {
    display: block !important;
}

.d-ib,
.d-ib-xs {
    display: inline-block !important;
}

@media (min-width: 576px) {
    .d-n-sm {
        display: none !important;
    }

    .d-i-sm {
        display: inline !important;
    }

    .d-b-sm {
        display: block !important;
    }

    .d-ib-sm {
        display: inline-block !important;
    }
}

@media (min-width: 768px) {
    .d-n-md {
        display: none !important;
    }

    .d-i-md {
        display: inline !important;
    }

    .d-b-md {
        display: block !important;
    }

    .d-ib-md {
        display: inline-block !important;
    }
}

@media (min-width: 992px) {
    .d-n-lg {
        display: none !important;
    }

    .d-i-lg {
        display: inline !important;
    }

    .d-b-lg {
        display: block !important;
    }

    .d-ib-lg {
        display: inline-block !important;
    }
}

@media (min-width: 1200px) {
    .d-n-xl {
        display: none !important;
    }

    .d-i-xl {
        display: inline !important;
    }

    .d-b-xl {
        display: block !important;
    }

    .d-ib-xl {
        display: inline-block !important;
    }
}