.section-ttl {
    &>* {
        display: block;
    }

    &-eng {
        color: rgba($color: #222222, $alpha: .56);
        font-family: Marcellus;
        letter-spacing: 0.1em;
        font-weight: 400;

        font-size: 1.5rem;
        line-height: calc(20 / 15);
        margin-bottom: 9px;

        @media (min-width: 992px) {
            font-size: 2rem;
            line-height: calc(27 / 20);
            margin-bottom: 11px;
        }
    }

    &-jp {
        font-family: $font-primary;
        letter-spacing: 0.1em;
        font-weight: 400;

        font-size: 2.6rem;
        line-height: calc(37 / 26);
        // margin: 11px 0  23px 0;

        @media (min-width: 992px) {
            font-size: 4rem;
            line-height: calc(58 / 40);
            // margin: 14px 0 45px 0;
        }
    }
}

.recruit {
    img {
        width: 100%;
    }

    padding: 59px 20px 0;

    @media (min-width: 768px) {
        padding: 70px 40px;
    }

    @media (min-width: 1200px) {
        padding: 120.5px 83px 0;
    }

    &-inner {
        max-width: 1057px;
        width: 100%;
        margin-inline: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;

        flex-direction: column-reverse;
        gap: 40px;

        @media (min-width: 768px) {
            flex-direction: row;
        }

        @media (min-width: 1200px) {
            justify-content: flex-start;
            gap: 80px;
        }

        @media (min-width: 1367px) {
            justify-content: flex-start;
            margin-inline: auto;
        }
    }

    &-icontent {

        @media (min-width: 768px) {
            max-width: 312px;
        }

        @media (min-width: 992px) {
            max-width: 495px;
        }

        @media (min-width: 1200px) {
            max-width: 600px;
        }
    }

    &-para {
        letter-spacing: 0.04em;
        font-weight: 400;

        font-size: 1.6rem;
        line-height: 1.65;
        margin: 23px 0 31px 0;

        @media (min-width: 768px) {
            margin: 17px 0 25px 0;
        }

        @media (min-width: 992px) {
            font-size: 1.8rem;
            line-height: calc(31 / 18);
        }

        @media (min-width: 1200px) {
            margin: 41px 0;
        }

    }

    &-arrow {
        color: #222222;

        &-txt {
            letter-spacing: 0.1em;
            font-weight: 400;

            font-size: 1.6rem;
            line-height: 1.33;

            @media (min-width: 992px) {
                font-size: 2rem;
                line-height: 1.35;
            }
        }

        &-content {
            color: #222222;
            border-top: 1px solid rgba($color: #222222, $alpha: .2);
            border-bottom: 1px solid rgba($color: #222222, $alpha: .2);
            display: flex;
            align-items: center;

            justify-content: space-between;
            padding: 16px 0;
            width: 100%;

            @media (min-width: 992px) {
                justify-content: flex-start;
                padding: 20px 0;
                width: fit-content;
                gap: 38px;
            }

            &:hover {
                cursor: pointer;

                .company-card-arrow-normal {
                    display: none;
                }

                .company-card-arrow-hover {
                    display: block;
                }
            }
        }

        &-img {
            max-width: 35px;

            @media (min-width: 992px) {
                max-width: 45px;
            }
        }
    }

    .bdr-top0 {
        border-top: 0 !important;
    }


    .primary-btn {
        width: 100%;
        justify-content: space-between;
        gap: 0;
        padding: 14px 0;
        border: 1px solid rgba($color: #222222, $alpha: .2);
        border-left: 0;
        border-right: 0;

        @include mq("tablet") {
            width: fit-content;
            justify-content: center;
            gap: 17px;
            padding: 19.5px 0;
        }

        &--icon {
            padding: 11.5px 12.5px;

            @include mq("tablet") {
                padding: 14px 15.5px;
            }

            img {
                width: 10px;
                height: 12px;

                @include mq("tablet") {
                    width: 14px;
                    height: 17px;
                }
            }
        }

        span {
            font-size: 1.8rem;
            line-height: calc(26 / 18);

            @include mq("bootstrap-lg") {
                font-size: 2rem;
                line-height: calc(29 / 20);
            }
        }
    }

}