.company {
    background: url(../img/common/dotted-bg.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    // padding: 60px 0;

    // @media (min-width: 768px) {
    //     padding: 117px 0 120px;
    // }

    img {
        transition: .5s ease-in-out;
        width: 100%;
    }

    &-inner {
        margin-inline: auto;
        justify-content: center;
        display: flex;

        gap: 3px;
        flex-wrap: wrap;
        margin-top: 21px;

        @media (min-width: 768px) {
            margin-top: 30px;
            flex-wrap: unset;
            gap: 2px;
        }

        @media (min-width: 992px) {
            margin-top: 54.7px;
        }
    }

    &-card {
        color: #222222;
        overflow: hidden;
        width: 100%;
        width: calc(100%/2 - 3px);

        @media (min-width: 768px) {
            max-width: 298.5px;
        }

        &-img {
            overflow: hidden;

            img {
                display: block;
            }
        }

        &:hover {
            cursor: pointer;

            .company-card-arrow-normal {
                display: none;
            }

            .company-card-arrow-hover {
                display: block;
            }

            img {
                transition: all .5s ease-in;
            }

            .company-card-img {

                img {
                    transition: all .5s ease;
                    transform: scale(1.2);
                    max-width: 100%;
                    max-height: 100%;
                }
            }
        }

        &-content {
            background-color: #fff;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 14px 15px 16px;

            @media (min-width: 992px) {
                padding: 35px 20px 37px 30px;
            }
        }

        &-txt {
            &-eng {
                font-family: Marcellus;
                font-weight: 400;
                letter-spacing: 0.1em;
                line-height: 1.33;

                font-size: 1.2rem;
                margin-bottom: 2px;

                @media (min-width: 992px) {
                    font-size: 1.5rem;
                    margin-bottom: 10px;
                }
            }

            &-jp {
                font-weight: 400;
                letter-spacing: 0.1em;
                line-height: 1.35;

                font-size: 1.6rem;

                @media (min-width: 992px) {
                    font-size: 2rem;
                }
            }
        }

        &-arrow {

            img {
                transition: all .5s ease-in;
                width: 25px;
                height: 25px;

                @media (min-width: 992px) {
                    width: 45px;
                    height: 45px;
                }
            }

            &-hover {
                display: none;
                transition: all .5s ease;
            }
        }
    }
}