.banner {
    background-image: url(.././img/common/dotted-bg.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    img {
        width: 100%;
    }

    &-swiper {
        .swiper {
            &-slide {
                * {
                    // max-height: 680px;
                    width: 100%;
                    object-fit: cover;
                }
            }
        }
    }

    &-inner {
        position: relative;

        @keyframes zoom {
            from {
                transform: scale(1);
            }

            to {
                transform: scale(1.2);
            }
        }

        .swiper-wrapper {

            .swiper-slide {
                overflow: hidden;

                img {
                    width: 100%;
                    height: auto;
                }
            }

            .swiper-slide-prev,
            .swiper-slide-active,
            .swiper-slide-duplicate-active {

                img {
                    animation: none;
                    @include mq("tablet"){
                        animation: zoom 10s linear 0s 1 normal both;
                    }
                    // animation-duration: 10s;
                    // animation-fill-mode: forwards;
                    // animation-timing-function: ease;
                }
            }
        }
    }

    &-bottom {
        position: absolute;
        z-index: 1;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        flex-direction: column;
        background-color: #fff;
        width: calc(100% - 10px);
        bottom: 220px;
        left: 5px;
        right: 5px;

        @media (min-width: 768px) {
            background-color: rgba(255, 255, 255, .9);
            flex-direction: row;
            width: 100%;
            bottom: 108px;
            left: 0;
        }

        @media (min-width: 992px) {
            bottom: 113px;
        }

        &-yellow {
            background-color: #FFE100;
            width: 100%;
            padding: 12px 0;
            text-align: center;

            @media (min-width: 768px) {
                width: unset;
                padding: 23px 30px 22px;
            }
        }

        &-white {
            display: flex;
            padding: 12px 10px 15px 14px;

            @media (min-width: 768px) {
                padding: 0;
            }

            &-date {
                margin-right: 24px;
                line-height: 1.33;

                @media (min-width: 768px) {
                    margin: 0 23px 0 30px;
                }
            }

            &-date,
            &-txt {
                .banner-bottom-txt {
                    font-weight: 400;
                    letter-spacing: 0;

                    font-size: 1.6rem;
                    line-height: calc(24 / 18);

                    @media (min-width: 992px) {
                        font-size: 1.8rem;
                        line-height: 1.33;
                    }
                }
            }
        }

        &-txt {
            font-weight: 400;
            letter-spacing: 0;

            font-size: 1.8rem;
            line-height: calc(26 / 18);

            @media (min-width: 992px) {
                font-size: 1.8rem;
                line-height: 1.33;
            }

        }
    }

    &-nav {
        display: grid;
        place-items: center;

        // padding: 18px 20px;
        grid-template-rows: 1fr;

        @media (min-width: 768px) {
            grid-template-columns: repeat(4, 1fr);
        }

        a {
            color: #222222;
            width: 100%;

            display: block;
            padding: 15px 18px;
            border-bottom: 1px solid rgba($color: #222222, $alpha: 0.2);

            &:hover {
                opacity: .6;
            }

            @media (min-width: 768px) {
                border-bottom: 0;
                display: flex;
                padding: 30px 0;
            }

            &:last-of-type {
                border-bottom: 0;

                &::after {
                    display: none;
                }
            }

            &::after {
                @media (min-width: 768px) {
                    content: " ";
                    display: block;
                    width: 1px;
                    height: 48px;
                    background-color: rgba($color: #222222, $alpha: 0.2);
                }
            }

            span {
                display: flex;
                align-items: center;
                gap: 21px;

                font-weight: 400;
                line-height: 1.35;
                letter-spacing: 0.1em;

                // font-size: 1.4rem;
                flex-direction: row;
                justify-content: space-between;
                font-size: 1.8rem;

                @media (min-width: 768px) {
                    flex-direction: column;
                    justify-content: center;
                    margin-inline: auto;
                    font-size: 1.5rem;
                }

                @media (min-width: 992px) {
                    font-size: 2rem;
                }

                &::after {
                    content: " ";
                    display: block;

                    height: 6px;
                    width: 11px;
                    background-image: url(.././img/banner/arrow-down.svg);
                    background-size: cover;
                    background-repeat: no-repeat;
                }
            }
        }
    }

    &-ttl {
        position: absolute;
        left: 26.2px;
        top: 35%;
        z-index: 1;
        transform: translateY(-50%);

        @media (min-width: 768px) {
            left: 75.7px;
            top: 45%;
        }

        &-small {
            max-width: 90px;
            margin-left: 212px;
            margin-bottom: 12.6px;

            display: none;

            @media (min-width: 768px) {
                display: block;
            }
        }

        &-large {
            max-width: 469.68px;

            display: none;

            @media (min-width: 768px) {
                display: block;
            }
        }

        &-sp {
            max-width: 178.72px;

            display: block;

            @media (min-width: 768px) {
                display: none;
            }
        }
    }

    &-top {
        transition: all .5s ease;
        display: none;
        position: fixed;
        bottom: 30px;
        right: 30px;
        background: #F2F2F2;
        padding: 22px 24px;
        // bottom: 68px;
        // position: absolute;
        // right: 0;
        z-index: 2;

        @include mq("tablet") {
            display: block;
        }

        &:hover {
            background: $color-yellow;
        }

        &.position-btm {
            bottom: 36px;
        }

        img {
            width: 12px;
            height: 16px;
            transform: rotate(-90deg);
        }
    }

    &-scroll {
        position: absolute;
        z-index: 1;
        left: -42px;
        // left: 19px;
        // bottom: -5px;
        transform: translateY(-50%)rotate(90deg);
        // transform-origin: right;
        display: none;
        justify-content: center;
        align-items: center;
        gap: 11.2px;

        @media (min-width: 768px) {
            // bottom: 63px;
            top: 79%;
            display: flex;
        }

        &-txt {
            // writing-mode: tb;
            color: #fff;
            font-size: 8px;
            font-weight: 500;
            // letter-spacing: 1.6;
            line-height: 1.37;
            letter-spacing: 0.2em;
        }

        &::after {
            content: " ";
            display: block;
            height: 2px;
            width: 110px;
            background-color: #fff;
        }
    }
}

.slider {
    margin-top: 40.5px;

    @include mq("tablet") {
        margin-top: 50.5px;
    }

    img {
        width: 100%;
        height: 100%;
        display: block;
        max-height: 180px;
        object-fit: cover;
    }

    .swiper {

        &-wrapper {
            transition-timing-function: linear;
            // pointer-events: none;

            &.speedAnimation {
                transition-duration: 1000ms !important;
            }
        }


        &-slide {
            width: auto;
        }

        .swiper-button-prev,
        .swiper-button-next {
            color: $color-white;
            background: rgba($color: #000000, $alpha: .35);
            border-radius: 50%;
            width: 35px;
            height: 35px;
            top: 50%;
            transform: translateY(-50%);
            margin-top: 0;

            @include mq("tablet") {
                width: 40px;
                height: 40px;
            }

            &::after {
                font-size: 8px;

                @include mq("tablet") {
                    font-size: 10px;
                }
            }
        }

        .swiper-button-prev.swiper-button-disabled,
        .swiper-button-next.swiper-button-disabled {
            opacity: 1;
        }

        .swiper-button-prev {
            left: 10px;

            @include mq("tablet") {
                left: 26px;
            }
        }

        .swiper-button-next {
            right: 10px;

            @include mq("tablet") {
                right: 26px;
            }
        }
    }
}