.footer {
    margin-bottom: 50px;

    @include mq("tablet") {
        margin-bottom: 0;
    }

    &-content {
        background: $color-black-01;
        padding-block: 0;
        padding-inline: 0;

        @include mq("tablet") {
            padding-block: 80px;
            padding-inline: 20px;
        }

        @include mq("desktop") {
            padding-inline: 0;
        }

        .menu {
            &-block {
                max-width: 1174px;
                width: 100%;
                margin-inline: auto;
                display: block;

                @include mq("tablet") {
                    display: flex;
                    justify-content: flex-start;
                    gap: 50px 100px;
                    flex-wrap: wrap;
                }

                // @include mq("tablet") {
                //     gap: 50px;
                // }

                @include mq("bootstrap-lg") {
                    gap: 10px;
                    flex-wrap: nowrap;
                    justify-content: center;
                }

                @include mq("desktop") {
                    gap: 50px;
                }
            }

            &-list {
                width: 100%;

                // flex: 1;
                &:nth-child(1) {
                    max-width: 100%;

                    @include mq("tablet") {
                        max-width: 290px;
                    }
                }

                &:nth-child(2) {
                    max-width: 100%;

                    @include mq("tablet") {
                        max-width: 190px;
                    }

                    // li:nth-child(2) {
                    //     .menu-list--link {
                    //         pointer-events: all;
                    //     }

                    //     // .menu-list-content {
                    //     //     opacity: 0;
                    //     //     max-height: 0;
                    //     // }

                    //     .menu-down {
                    //         &::after {
                    //             @include mq("tablet") {
                    //                 content: "";
                    //                 top: 7px;
                    //                 transform: none;
                    //             }
                    //         }
                    //     }
                    // }
                }

                &:nth-child(3) {
                    max-width: 100%;

                    @include mq("tablet") {
                        max-width: 256px;
                    }
                }

                &:nth-child(4) {
                    max-width: 100%;

                    @include mq("tablet") {
                        max-width: 292px;
                    }
                }

                &--item {
                    margin-bottom: 0px;

                    @include mq("tablet") {
                        margin-bottom: 27px;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                    &.mb15{
                        margin-bottom: 0;
                        @include mq("tablet") {
                            margin-bottom: 14px;
                        }
                    }
                }

                &-content {
                    padding-left: 0;
                    // opacity: 0;
                    // max-height: 0;

                    @include mq("tablet") {
                        // display: block;
                        padding-left: 26px;
                        // opacity: 1;
                        // max-height: 100%;
                    }

                    .menu-list--link {
                        background: $color-primary;

                        @include mq("tablet") {
                            background: transparent;
                        }
                    }
                }

                &--link {
                    cursor: pointer;
                    width: 100%;
                    display: block;
                    position: relative;
                    width: 100%;
                    background: #444444;
                    color: $color-white;
                    border-bottom: 1.5px solid rgba(255, 255, 255, 0.1);

                    font-weight: 700;
                    font-size: 1.6rem;
                    line-height: calc(24 / 16);
                    letter-spacing: 0.05em;
                    padding-block: 14px 16px;
                    padding-inline: 20px 21.5px;

                    &:hover {
                        opacity: 0.7;
                    }

                    @include mq("tablet") {
                        width: fit-content;
                        border-bottom: none;
                        pointer-events: none;
                        padding-block: 0 15px;
                        padding-inline: 19px 0;
                    }

                    @include mq("bootstrap-lg") {
                        font-size: 1.3rem;
                        line-height: calc(22 / 14);
                    }

                    @include mq("desktop") {
                        font-size: 1.6rem;
                        line-height: calc(24 / 16);
                    }

                    &:hover {
                        background: rgba(68, 68, 68, 0.8);
                    }

                    &.pl {
                        padding-left: 39px;

                        @include mq("tablet") {
                            padding-left: 19px;
                        }
                    }

                    &.bl-ylw {
                        pointer-events: all;
                        position: relative;
                        padding-bottom: 16px;

                        @include mq("tablet") {
                            padding-bottom: 0;
                        }

                        &::before {
                            content: "";
                            position: absolute;
                            background: #ffe100;
                            width: 4px;
                            height: 18px;
                            top: 50%;
                            transform: translateY(-50%);
                            left: 19px;

                            @include mq("tablet") {
                                width: 4px;
                                height: 14px;
                                top: 13px;
                                left: 0;
                            }
                        }
                    }

                    &.l-dash {
                        font-weight: 500;
                        pointer-events: all;

                        @include mq("tablet") {
                            letter-spacing: normal;
                            padding: 0 0 10px;
                            position: relative;
                            display: flex;
                            align-items: baseline;
                            gap: 8px;
                        }

                        &::before {
                            content: "";
                            position: absolute;
                            background: $color-white;
                            width: 6px;
                            height: 1px;
                            top: 13px;
                            left: -19px;
                        }
                    }

                    &.aft-arw {
                        position: relative;

                        &:after {
                            content: "";
                            position: absolute;
                            background: url(../img/common/arrow.png) no-repeat;
                            background-position: center;
                            background-size: cover;
                            width: 11px;
                            height: 7px;
                            top: 50%;
                            transform: translateY(-50%);
                            right: 21.5px;

                            @include mq("tablet") {
                                top: 12px;
                                transform: none;
                                right: -21.5px;
                            }

                            @include mq("bootstrap-lg") {
                                top: 6px;
                                transform: none;
                                right: -15px;
                            }

                            @include mq("desktop") {
                                top: 12px;
                                transform: none;
                                right: -21.5px;
                            }
                        }
                    }

                    &.aft-box {
                        position: relative;

                        &:after {
                            content: "";
                            position: absolute;
                            background: url(../img/common/box.png) no-repeat;
                            background-position: center;
                            background-size: cover;
                            width: 10px;
                            height: 10px;
                            top: 50%;
                            transform: translateY(-50%);
                            right: 22px;

                            @include mq("tablet") {
                                top: 10px;
                                transform: none;
                                right: -21.5px;
                            }

                            @include mq("bootstrap-lg") {
                                top: 5px;
                                transform: none;
                                right: -15px;
                            }

                            @include mq("desktop") {
                                top: 10px;
                                transform: none;
                                right: -21.5px;
                            }
                        }
                    }

                    &.menu-down {
                        position: relative;

                        &::after {
                            content: "";
                            position: absolute;
                            background: url(../img/common/add.png) no-repeat;
                            background-position: center;
                            background-size: cover;
                            width: 12px;
                            height: 12px;
                            top: 50%;
                            transform: translateY(-50%);
                            right: 20px;

                            @include mq("tablet") {
                                content: none;
                                top: 10px;
                                transform: none;
                                right: -22px;
                            }
                        }

                        &.active::after {
                            background: url(../img/common/minus.png) no-repeat
                                center;
                            background-size: cover;
                            width: 12px;
                            height: 2px;

                            @include mq("tablet") {
                                top: 10px !important;
                                transform: none;
                            }
                        }

                        &::before {
                            content: "";
                            position: absolute;
                            background: #ffe100;
                            width: 4px;
                            height: 18px;
                            top: 50%;
                            transform: translateY(-50%);
                            left: 19px;

                            @include mq("tablet") {
                                width: 4px;
                                height: 14px;
                                top: 13px;
                                left: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    &-box {
        background: url(../img/group/group-bg.png) no-repeat;
        background-size: cover;
        background-position: center;
        padding: 5px 0;

        @include mq("tablet") {
            padding: 28px 20px 30px;
        }

        &--txt {
            max-width: 1196px;
            margin-inline: auto;
            display: flex;
            flex-wrap: wrap;
            align-items: stretch;
            justify-content: flex-start;
            // gap: 10px 15px;
            gap: 0;
            font-weight: 500;
            font-size: 1.4rem;
            line-height: calc(20 / 14);

            @include mq("tablet") {
                font-size: 1.3rem;
                align-items: center;
                justify-content: center;
                gap: 0 30px;
                line-height: calc(25 / 14);
            }

            &:nth-child(2) {
                a:last-child {
                    &::after {
                        content: none;
                    }
                }
            }

            a {
                text-decoration: none;
                color: $color-primary;
                width: calc((100% / 2) - .5px);
                padding: 10px 19px 9px 7.5px;
                border-bottom: 1px solid rgba($color: #222, $alpha: .2);
                @include mq("tablet") {
                    border-bottom: 0;
                    width: fit-content;
                    padding: 0;
                }
                &:nth-child(odd){
                    border-right: 1px solid rgba($color: #222, $alpha: .2);
                    padding: 10px 7.5px 9px 20px;
                    @include mq("tablet") {
                        border-right: 0;
                        padding: 0;
                    }
                }
                &:last-child{
                    border-bottom: 0;
                }

                &:hover {
                    opacity: 0.7;
                }

                position: relative;

                &::after {
                    content: none;
                    position: absolute;
                    background: $color-primary;
                    width: 1px;
                    height: 16px;
                    top: 3px;
                    right: -16px;

                    @include mq("tablet") {
                        content: "";
                    }
                }
            }
        }
    }

    &-copyright {
        padding: 31px 35px;

        @include mq("tablet") {
            padding: 28px 20px 14.3px;
        }

        img {
            width: 200px;
            margin-inline: auto;

            @include mq("tablet") {
                width: 270px;
            }
        }

        p {
            text-align: center;
            font-size: 1.1rem;
            line-height: calc(17.5 / 11);
            margin-top: 15px;

            @include mq("tablet") {
                text-align: end;
                font-size: 1rem;
                line-height: calc(15 / 10);
                margin-top: 0;
            }
        }
    }
}
