.contact {
    padding-block: 30px;
    @include mq("tablet") {
        padding-block: 100px;
    }

    &-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        background: url(../img/common/dotted-bg.png) no-repeat;
        background-position: center;
        background-size: cover;
        // max-width: 600px;
        // width: 100%;

        @include mq("tablet") {
            flex-direction: row;
            background: url(../img/common/dotted-bg.png) no-repeat;
            // max-width: 100%;
        }
        &:hover{
            .primary-btn--icon {
                background: $color-yellow;
            }
            .contact-col:last-child{
                img{
                    // transform: scale(1.2);
                    opacity: .7;
                }
            }
        }

        @media (max-width: 767px) {
            max-width: 670px;
            margin-inline: auto;
        }
    }

    &-col {
        color: $color-primary;
        &:first-child {
            // background: url(../img/common/dotted-bg.png) no-repeat;
            // background-position: center;
            // background-size: cover;
            max-width: 600px;
            width: 100%;
            padding: 40px 40px 41.5px;

            @include mq("tablet") {
                padding: 30px 20px;
            }

            @include mq("bootstrap-lg") {
                padding: 40px 20px;
            }

            @include mq("desktop") {
                padding: 70px 64px 66px;
            }
        }
        &:last-child{
            overflow: hidden;
            img{
                transition: all .5s ease;
            }
        }

        &--ttl {
            font-size: 2rem;
            line-height: calc(29 / 20);
            letter-spacing: .1em;

            @include mq("tablet") {
                font-size: 2.5rem;
                line-height: calc(32 / 26);
            }

            @include mq("bootstrap-lg") {
                font-size: 4rem;
                line-height: calc(58 / 40);
            }
        }

        &--desc {
            font-size: 1.6rem;
            line-height: calc(28 / 16);
            letter-spacing: .04em;
            margin-block: 20px;

            @include mq("tablet") {
                font-size: 1.6rem;
                line-height: calc(24 / 16);
                letter-spacing: .04em;
                margin-block: 21px 41px;
            }

            @include mq("bootstrap-lg") {
                font-size: 1.8rem;
                line-height: calc(29 / 18);
            }
        }

        .primary-btn {
            width: fit-content;
            gap: 10px;
            padding: 14px 0;
            border: 1px solid rgba($color: #222222, $alpha: .2);
            border-left: 0;
            border-right: 0;
            @include mq("tablet") {
                gap: 14px;
                padding: 19.5px 0;
            }

            &--icon {
                padding: 11.5px 12.5px;
                @include mq("tablet") {
                    padding: 14px 15.5px;
                }
            }

            span {
                font-weight: 500;
                font-size: 1.6rem;
                line-height: calc(24 / 16);

                @include mq("tablet") {
                    font-size: 1.8rem;
                    line-height: calc(26 / 18);
                }

                @include mq("bootstrap-lg") {
                    font-size: 2rem;
                    line-height: calc(29 / 20);
                }
            }
        }
    }
}