.history {
    &-block {
        .banner-nav {
            background: url(../img/company-section/history/history-bg.jpg) no-repeat;
            background-position: center;
            background-size: cover;

            @media (min-width: 768px) {
                grid-template-columns: repeat(5, 1fr);
            }

            a {
                span {
                    letter-spacing: 0.1em;
                }
            }
        }
    }

    &-content {
        display: block;
        position: relative;
        margin-left: 24px;
        width: 100%;
        max-width: 1105px;
        width: calc(100% - 24px);

        @media (min-width: 768px) {
            margin-left: 40px;
            width: calc(100% - 40px);
        }

        @media (min-width: 992px) {
            margin-left: 90px;
            width: calc(100% - 90px);
        }

        @media (min-width: 1200px) {
            margin-left: 141px;
            width: calc(100% - 141px);
        }

        @media (min-width: 1366px) {
            margin-inline: auto;
            width: 100%;
        }

        &::after {
            content: "";
            position: absolute;
            width: 1px;
            height: 100%;
            background: rgba($color: #222222, $alpha: 0.5);
            left: 0;
            top: 0px;

            @media (min-width: 768px) {
                left: 0;
            }

            @media (min-width: 992px) {
                left: 0;
            }
        }

        .container-md {
            margin-inline: 0;
        }

        .section {
            padding-block: 60px 40px;

            @media (min-width: 768px) {
                padding-block: 80px;
            }

            @media (min-width: 1200px) {
                padding-block: 120px 233px;
            }
        }


    }

    &-list {
        padding-left: 0;

        @media (min-width: 768px) {
            padding-left: 7px;
        }

        &-item {
            display: flex;
            flex-direction: column;
            gap: 11px;
            align-items: flex-start;
            margin-bottom: 40px;
            position: relative;
            list-style-type: disc;

            @media (min-width: 768px) {
                margin-bottom: 50px;
                flex-direction: row;
                gap: 30px;
            }

            &:last-child {
                margin-bottom: 0;
            }

            @media (min-width: 992px) {
                gap: 60px;
            }

            &.-fd {
                @media (max-width: 767px) {
                    flex-direction: column-reverse;
                }

                &::before {
                    top: unset;
                    bottom: 65px;
                }
            }

            &::before {
                content: "";
                position: absolute;
                background: #ffe100;
                border-radius: 10px;
                width: 8px;
                height: 8px;
                left: -24px;
                top: 12px;
                z-index: 2;

                @media (min-width: 768px) {
                    width: 15px;
                    height: 15px;
                    left: -34px;
                    top: 13px;
                }
            }

            .flex-wpr {
                display: flex;
                flex-direction: column;
                gap: 11px;

                @media (min-width: 768px) {
                    gap: 60px;
                    flex-direction: row;
                }
            }

            &-text {
                font-weight: 700;
                letter-spacing: 0.1em;
                font-size: 2.2rem;
                line-height: calc(30 / 22);
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 15px;

                @media (min-width: 768px) {
                    gap: 20px;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: unset;
                    font-size: 2.4rem;
                    line-height: calc(40 / 24);
                }

                @media (min-width: 992px) {
                    font-size: 3rem;
                    line-height: calc(40 / 30);
                }

                span {
                    letter-spacing: 0.1em;
                    font-size: 1.6rem;
                    line-height: calc(21 / 16);
                    font-weight: 400;
                    display: block;

                    @media (min-width: 768px) {
                        margin-top: 5px;
                    }
                }
            }

            &-txt {
                p {
                    display: flex;
                    align-items: baseline;
                    font-size: 1.6rem;
                    letter-spacing: 0.05em;
                    line-height: calc(24 / 16);

                    // @media (min-width: 768px) {
                    //     // align-items: flex-start;
                    //     line-height: calc(28 / 18);
                    //     font-size: 1.8rem;
                    // }

                    @media (min-width: 992px) {
                        // align-items: flex-start;
                        line-height: calc(28 / 18);
                        font-size: 1.8rem;
                    }

                    span {
                        max-width: fit-content;
                        width: 100%;
                        font-weight: 700;
                    }
                }

                .text-detail {
                    font-weight: 400;
                    font-size: 1.6rem;
                    letter-spacing: 0.05em;
                    line-height: calc(25.5 / 16);
                    margin-left: 20px;

                    @media (min-width: 992px) {
                        // margin-left: 21px;
                        line-height: calc(28 / 18);
                        margin-left: 30px;
                        font-size: 1.8rem;
                    }

                    &.-ml19 {
                        margin-left: 10px;

                        @media (min-width: 992px) {
                            margin-left: 19px;
                        }
                    }
                }

                .mb-15 {
                    margin-bottom: 13px;

                    @media (min-width: 768px) {
                        margin-bottom: 15px;
                    }
                }
            }
        }

        .img-wpr {
            max-width: 320px;

            @media (max-width: 767px) {
                margin-top: 33px;
                // margin-top: 43px;
            }

            @media (min-width: 768px) and (max-width: 991px) {
                max-width: 200px;
            }

            @media (min-width: 992px) and (max-width: 1199px) {
                max-width: 260px;
            }
        }

        .history-img-wpr {
            @media (min-width: 768px) {
                position: absolute;
                right: 0px;
            }

            &.-mod {
                top: 50px;
            }

            &.-mod2 {
                // top: 50px;
                top: 403px;
            }

            &.-mod3 {
                // top: 96px;
                top: -245px;
            }

            &.-mod4 {
                top: 113px;

                .img-wpr {
                    margin-top: 0;
                }
            }
        }

        .img-txt {
            font-weight: 700;
            margin-top: 12px;
            font-size: 1.6rem;
            letter-spacing: 0.05em;
            line-height: calc(21 / 16);

            &.mb-40 {
                margin-bottom: 40px;
            }
        }
    }
}