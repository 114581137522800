.reception {
    // padding: 58px 20px 60px;

    // @media (min-width: 768px) {
    //     padding: 70px 40px 80px;
    // }

    // @media (min-width: 1200px) {
    //     padding: 117px 83px 130px;
    // }

    img {
        width: 100%;
        transition: all .5s ease;
    }

    &-inner {
        height: 100%;
        display: flex;
        justify-content: center;
        align-content: center;

        margin-top: 21px;
        flex-direction: column;

        @media (min-width: 768px) {
            margin-top: 43px;
            flex-direction: row;
            gap: 40px;
        }
    }

    .company-card-arrow {
        img {
            width: 35px;
            height: 35px;

            @media (min-width: 992px) {
                width: 45px;
                height: 45px;
            }
        }
    }

    &-item {
        height: 100%;
        position: relative;

        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        padding: 16px 0;
        border-top: 1px solid rgba($color: #222, $alpha: .2);

        @media (min-width: 768px) {
            border: 1px solid rgba($color: #222, $alpha: .4);
            max-width: 580px;
        }

        @media (min-width: 992px) {
            padding: 31.5px 30px;
        }

        &:last-child {
            border-bottom: 1px solid rgba($color: #222, $alpha: .2);

            @include mq("tablet") {
                border: 1px solid rgba($color: #222, $alpha: .4);
            }
        }

        &-img {
            transition: .5s ease-in-out;
            position: absolute;
            z-index: -1;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;

            opacity: 0;
            transition: opacity .5s ease;

            img {
                background-position: 100% 100%;
                background-size: cover;
                background-repeat: no-repeat;
                height: 100%;
                width: 100%;
                transition: all .5s ease;
            }
        }

        &:hover {
            cursor: pointer;
            border: 1px solid transparent;

            .reception-item-img {
                opacity: 1;
            }

            .reception-item-txt {
                color: #fff;
            }

            .company-card-arrow-normal {
                display: none;
            }

            .company-card-arrow-hover {
                display: block;
            }
        }

        &-txt {
            transition: .5s ease-in-out;
            font-weight: 400;
            letter-spacing: 0.1em;

            font-size: 1.8rem;
            line-height: calc(24/18);

            @media (min-width: 992px) {
                line-height: calc(27/20);
                font-size: 2rem;
            }
        }

        &-arrow {
            transition: .5s ease-in-out;

            width: 35px;
            height: 35px;

            @media (min-width: 992px) {
                width: 45px;
                height: 45px;
            }
        }
    }
}