.office{
    background-color: #f9f9f9;
    padding-top: 60px;

    @include mq("tablet") {
        padding-top: 35px;
    }

    .contact{
        background-color: #fff;

        &-col:first-child{
            padding: 35px 22px;

            @include mq("tablet") {
                padding: 35px 15px;
            }

            .primary-btn{
                max-width: 371px;
                margin: 0 auto;
                border: none;
                gap: 8px;
                padding: 0;
    
                @include mq("bootstrap-lg") {
                    gap: 18px;
                }

                span{
                    font-size: 1.6rem;

                    @include mq("tablet") {
                        font-size: 1.5rem;
                    }

                    @include mq("bootstrap-lg") {
                        font-size: 2rem;
                    }
                }
            }
        }
    }

    &__ttl{
        font-size: 2.4rem;
        line-height: 35px;
        letter-spacing: 0.01em;
        color: #000;

        @include mq("tablet") {
            font-size: 3.2rem;
            line-height: 46px;
            letter-spacing: 0.1em;
        }
    }

    &__desc{
        font-size: 1.4rem;
        line-height: 22.7px;
        margin-top: 10px;
        display: block;
        padding-right: 14px;

        @include mq("phablet") {
            padding-right: 0;
        }

        @include mq("tablet") {
            font-size: 1.6rem;
            line-height: 24px;
        }
    }

    &__flex{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;
        gap: 6px;

        @include mq("tablet") {
            flex-direction: row;
            align-items: center;
            gap: 10px;
        }

        & span.link{
            display: flex;

            a{
                transition: all .5s ease;
                border: 1px solid #FFE100;
                line-height: 22px;
                width: 42px;
                height: 42px;
                border-radius: 50%;
                text-align: center;
                // line-height: 17.5px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #000;
                font-size: 14px;

                @include mq("tablet") {
                    border: 2px solid #FFE100;
                    width: 50px;
                    height: 23px;
                    border-radius: 0;
                    font-size: 16pa;
                }

                &:hover{
                    opacity: .7;
                }
                
                &:not(:last-child){
                    margin-right: 10px;

                    @include mq("tablet") {
                        margin-right: 12.5px;
                    }
                }
            }
        }

        &.-column{
            flex-direction: column;
            text-align: center;
            gap: 20px;
        }
    }

    table{
        width: 100%;
        margin-block: 32px 60px;
        border: 1px solid #fff;
        border-bottom: none !important;
        border-top: 1px solid #fff;
        background-color: #fff;
        position: relative;
        
        @include mq("tablet") {
            border-top: 3px solid #fff;
        }

        &::before,
        &::after{
            content: '';
            height: 100%;
            width: 1px;
            background-color: #fff;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }

        &::before{
            left: -1px;
        }
        &::after{
            right: -1px;
        }

        @include mq("tablet") {
            margin-block: 20px 80px;
            // border: 2px solid #fff;
        }

        &:last-of-type{
            margin-bottom: 90px;

            @include mq("tablet") {
                margin-bottom: 150px;
            }
        }

        tr th{
            font-size: 1.6rem;
            font-weight: 700;
            letter-spacing: 0.05em;
            line-height: 24px;
            // padding: 12.5px 14px;
            height: 49px;
            background-color: #E2E2E2;

            @media (max-width: 767px) {
                letter-spacing: 0;
                padding: 8px 5px !important;
                
            }

            &.wd37{
                width: 38.80%;
                
                @include mq("tablet") {
                    width: 37.30%;
                }
            }
            &.wd40{
                width: 61.49%;
                
                @include mq("tablet") {
                    width: 40.490%;
                }
            }
            &.wd22{
                width: 22.20%;
                display: none;

                @include mq("tablet") {
                    display: table-cell;
                }
            }
            &.wd62{
                width: 64.49%;

                @include mq("tablet") {
                    width: 62%;
                }
            }

            &:not(:last-child){
                border-right: 2px solid #fff;
            }
        }

        tr td{
            border-bottom: 1px solid #707070;
            border-right: 1px solid #707070;

            &.thirdcolumn{
                padding-left: 7.5px;
                border-bottom: none;
                display: none;

                @include mq("tablet") {
                    border-right: none;
                    display: table-cell;
                }
            }

            &.noborder{
                border-bottom: none;
            }
            &.noborder-right{
                border-right: none;
            }

        }

        tr th,
        tr td{
            vertical-align: middle;
            line-height: 24px;
            padding: 8px;

            @include mq("tablet") {
                padding: 7.5px 7.5px 7.5px 23px;
            }
        }

        tr:last-of-type td{
            border-bottom: none;
        }

    }
}