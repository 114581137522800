.primary-btn {
    color: $color-primary;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid rgba(34, 34, 34, 0.4);
    width: 100%;
    padding: 11.5px 14px 11.5px 19px;
    border-radius: 0;

    @include mq("tablet") {
        padding: 22px 10px 21px 10px;
    }

    @include mq("desktop") {
        padding: 22px 19px 21px 24px;
    }

    span {
        font-size: 1.5rem;
        line-height: calc(21 / 15);
        letter-spacing: 0.1em;

        @include mq("tablet") {
            font-size: 1.6rem;
            line-height: calc(24 / 16);
            letter-spacing: 0.1em;
        }

        @include mq("desktop") {
            font-size: 1.8rem;
            line-height: calc(26 / 18);
        }
    }

    &:hover {
        .primary-btn--icon {
            background: $color-yellow;
        }
    }

    &--icon {
        transition: all 0.5s ease;
        background: rgba(34, 34, 34, 0.1);
        border-radius: 50%;
        padding: 11.5px 12.5px;

        @include mq("tablet") {
            padding: 14px 15.5px;
        }

        img {
            width: 10px;
            height: 12px;

            @include mq("tablet") {
                width: 14px;
                height: 17px;
            }
        }
    }

    &.-secondary {
        border: none;
        justify-content: center;
        flex-direction: row-reverse;
        gap: 14px;
        padding: 0;
        width: unset;

        @include mq("tablet") {
            gap: 17.7px;
        }

        .primary-btn--icon {
            width: 45px;
            height: 45px;
            transform: rotate(180deg);
            padding: 14px 15.5px;

            img {
                width: 14px;
                height: 17px;
            }
        }

        span {
            font-size: 2rem;
            line-height: calc(32 / 20);
            font-weight: 500;
        }
    }
}
