.contact {
    background: url(../img/common/dotted-bg.png) no-repeat;
    background-position: center;
    background-size: cover;

    padding-block: 40px 42px;

    @include mq("tablet") {
        padding-block: 79px 119.5px;
    }

    &-faq {
        &--heading {
            text-align: center;
            font-weight: 700;
            font-size: 2.2rem;
            line-height: calc(33 / 22);
            letter-spacing: .1em;
            margin-bottom: 31px;

            @include mq("tablet") {
                font-size: 3.2rem;
                line-height: calc(46/ 32);
                letter-spacing: .1em;
                margin-bottom: 39px;
            }
        }
        .primary-btn {
            // width: fit-content;
            box-shadow: 0 5px 10px rgba($color: #000000, $alpha: .08);
            // max-width: 340px;
            min-height: 95px;
            height: 100%;
            border: none;
            background: $color-white;
            border-left: 2px solid $color-yellow;
            padding: 20px 20.5px 21px 30px;
    
            @include mq("tablet") {
                min-height: 110px;
                padding: 24.5px 10px 24.5px 8px;
            }
    
            @include mq("bootstrap-lg") {
                padding: 24.5px 20.5px 24.5px 30px;
            }
    
            &--icon {
                padding: 13.5px 12px;
    
                img {
                    width: 11px;
                    height: 8px;
                }
    
                @include mq("tablet") {
                    padding: 15px;
    
                    img {
                        width: 10px;
                        height: 10px;
                    }
                }
    
                @include mq("bootstrap-lg") {
                    padding: 17.5px 16px;
    
                    img {
                        width: 13px;
                        height: 10px;
                    }
                }
            }
        }
    }

    &-row {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 15px;
        padding-bottom: 40px;
        margin-bottom: 30px;
        border-bottom: 1px solid #707070;

        @include mq("tablet") {
            flex-direction: row;
            gap: 15px;
            padding-bottom: 70px;
            margin-bottom: 50px;
        }

        @include mq("bootstrap-lg") {
            gap: 35px;
            margin-bottom: 70px;
        }

        span {
            font-weight: 700;
            font-size: 1.8rem;
            line-height: calc(27 / 18);
            letter-spacing: .1em;

            @include mq("tablet") {
                font-size: 1.5rem;
                line-height: calc(30.5 / 20);
            }

            @include mq("bootstrap-lg") {
                font-size: 1.7rem;
            }

            @include mq("desktop") {
                font-size: 2rem;
            }
        }

        .-ml5 {
            margin-left: 5px;
        }
    }

    &-wpr {
        
    .primary-btn {
        // width: fit-content;
        box-shadow: 0 5px 10px rgba($color: #000000, $alpha: .08);
        // max-width: 340px;
        min-height: 95px;
        height: 100%;
        border: none;
        background: $color-white;
        border-left: 2px solid $color-yellow;
        padding: 20px 20.5px 21px 30px;

        @include mq("tablet") {
            min-height: 110px;
            padding: 24.5px 10px 24.5px 8px;
        }

        @include mq("bootstrap-lg") {
            padding: 24.5px 20.5px 24.5px 30px;
        }

        &--icon {
            padding: 13.5px 12px;

            img {
                width: 11px;
                height: 8px;
            }

            @include mq("tablet") {
                padding: 15px;

                img {
                    width: 10px;
                    height: 10px;
                }
            }

            @include mq("bootstrap-lg") {
                padding: 17.5px 16px;

                img {
                    width: 13px;
                    height: 10px;
                }
            }
        }
    }
    }

    &-card {
        min-height: auto;
        height: 100%;
        margin-bottom: 20px;

        border-left: 2px solid $color-yellow;
        background: $color-white;
        padding: 40px 9px 40px 17px;

        @include mq("tablet") {
            min-height: 277px;
            margin-bottom: 32px;
            padding: 37px 30px 20px 28px;
        }

        @include mq("bootstrap-lg") {
            padding: 37px 60px 20px 58px;
        }

        &:nth-child(2) {
            margin-bottom: 20px;

            @include mq("tablet") {
                margin-bottom: 40px;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }

        &--ttl {
            text-align: center;
            font-weight: 700;
            font-size: 2rem;
            line-height: calc(30.5 / 20);
            letter-spacing: .1em;
            margin-bottom: 15px;

            @include mq("tablet") {
                text-align: start;
                font-size: 2.2rem;
                line-height: calc(32 / 22);
                margin-bottom: 28px;
            }

            @include mq("bootstrap-lg") {
                font-size: 2.4rem;
                line-height: calc(35 / 24);
            }
        }

        &-flex {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            gap: 20px;

            @include mq("tablet") {
                flex-direction: row;
                gap: 10px;
            }

            @include mq("bootstrap-lg") {
                gap: 25px;
            }

            &--num {
                a {
                    display: block;
                    transition: all .3s ease;
                    text-align: center;
                    color: $color-primary;
                    pointer-events: all;
                    text-decoration: underline;

                    font-weight: 700;
                    font-size: 3.4rem;
                    line-height: calc(49 / 34);
                    letter-spacing: .1em;
                    margin-bottom: 20px;

                    &:hover {
                        opacity: .7;
                    }

                    @include mq("tablet") {
                        text-align: start;
                        text-decoration: none;
                        pointer-events: none;
                        font-size: 4.2rem;
                        line-height: calc(69 / 44);
                        margin-bottom: 4px;
                    }

                    @include mq("bootstrap-lg") {
                        font-size: 4.8rem;
                        line-height: calc(72 / 48);
                    }

                    @include mq("desktop") {
                        font-size: 5.4rem;
                        line-height: calc(79 / 54);
                    }
                }

                span {
                    text-align: center;
                    display: block;
                    font-size: 1.6rem;
                    line-height: calc(29 / 16);
                    letter-spacing: .05em;

                    @include mq("tablet") {
                        text-align: start;
                        font-size: 1.5rem;
                        line-height: calc(27 / 16);
                    }

                    @include mq("bootstrap-lg") {
                        font-size: 1.6rem;
                    }
                }
            }

            .primary-btn {
                max-width: 295px;
                padding: 20px 20px;
                min-height: 123px;
                border-left: none;
                box-shadow: 0 5px 15px rgba($color: #000000, $alpha: .08);

                // padding: 24.5px 20px 24.5px 30px;
                // margin-right: 10px;
                @include mq("tablet") {
                    max-width: 310px;
                    // margin-right: 0;
                    min-height: 110px;
                    padding: 24.5px 10px 24.5px 10px;
                }

                @include mq("bootstrap-lg") {
                    max-width: 400px;
                    padding: 24.5px 20.5px 24.5px 30px;
                }

                @include mq("desktop") {
                    max-width: 490px;
                }

                span {
                    font-size: 1.8rem;
                    line-height: calc(27 / 18);
                    letter-spacing: .1em;

                    @include mq("tablet") {
                        font-size: 1.6rem;
                        line-height: calc(29 / 20);
                    }

                    @include mq("bootstrap-lg") {
                        font-size: 1.8rem;
                    }

                    @include mq("desktop") {
                        font-size: 2rem;
                    }
                }
            }
        }
    }
}