.services {
    .primary-heading {
        margin-bottom: 20px;

        @include mq("tablet") {
            margin-bottom: 50px;
        }
    }

    &-row {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 44px;

        @include mq("tablet") {
            flex-direction: row;
            gap: 30px;
        }

        @include mq("bootstrap-lg") {
            gap: 60px;
        }
    }

    &-col {
        position: relative;
        width: 100%;

        &:first-child {
            width: 100%;
            max-width: 100%;

            @include mq("tablet") {
                max-width: 230px;
            }

            @include mq("bootstrap-lg") {
                max-width: 280px;
            }

        }

        &-outer {
            border-bottom: 1px solid rgba($color: #222222, $alpha: .2);

            &:first-child {
                border-top: 1px solid rgba($color: #222222, $alpha: .2);
            }

            &:last-child {
                border-bottom: none;

                @include mq("tablet") {
                    border-bottom: 1px solid rgba($color: #222222, $alpha: .2);
                }
            }

        }

        &-lft {
            position: relative;
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 16px;
            padding: 10px 15px 10px 15px;
            background: $color-white;
            transition: all .2s ease, border 0s ease, padding 0s ease;

            @include mq("tablet") {
                padding: 15.5px 0px 16.5px 5px;
                gap: 5px;
            }

            @include mq("bootstrap-lg") {
                padding: 15.5px 7px 16.5px 20px;
                gap: 14px;
            }

            &.active {
                border: 5px solid $color-yellow;
                padding: 5px 0px 5px 10px;

                @include mq("tablet") {
                    border: 4px solid $color-yellow;
                    padding: 10.5px 0px 11.5px 1px;
                }

                @include mq("bootstrap-lg") {
                    border: 7px solid $color-yellow;
                    padding: 8.5px 0px 9.5px 13px;
                }

                .services-col-lft--ttl {
                    font-weight: 700;
                }
            }

            &--img {
                width: 12px;
                height: 9px;
            }

            &--ttl {
                color: $color-primary;
                font-weight: 400;
                font-size: 1.6rem;
                line-height: calc(24 / 16);
                letter-spacing: .05em;

                @include mq("tablet") {
                    font-size: 1.6rem;
                    line-height: calc(26 / 16);
                }

                @include mq("bootstrap-lg") {
                    font-size: 1.8rem;
                    line-height: calc(26 / 16);
                }
            }
        }

        &-rgt {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            opacity: 0;
            max-height: 0;
            visibility: hidden;
            transition: all .2s ease;

            &--ttl {
                font-size: 1.8rem;
                line-height: calc(26 / 18);
                letter-spacing: .1em;
                padding-block: 9px 10.5px;
                border-top: 1px solid rgba($color: #222222, $alpha: .2);
                border-bottom: 1px solid rgba($color: #222222, $alpha: .2);

                @include mq("tablet") {
                    font-size: 2.5rem;
                    line-height: calc(40 / 28);
                    padding-block: 15px;
                }

                @include mq("bootstrap-lg") {
                    font-size: 2.8rem;
                    line-height: calc(40 / 28);
                    padding-block: 20px 21.5px;
                }
            }

            &.active-content {
                opacity: 1;
                max-height: 100%;
                visibility: visible;
                transition: all .2s ease;
                position: relative;
            }
        }
    }

    &-menu {
        // margin-top: 13px;

        .faq-wpr-q {
            display: flex;
            padding: 11.5px 15px 13.5px 20px;
            // margin-bottom: -44px;
            transition: all .2s ease, border 0s ease, padding 0s ease;
            @include mq("tablet"){
                display: none;
            }

            &.active {
                border: none;
                background: #DDDDDD;
                padding: 16.5px 20px 18.5px 25px;
                // margin-bottom: 0;
            }

        }

        &-acc {
            display: block;
            align-items: center;
            justify-content: space-between;
            border: 5px solid $color-yellow;
            // &.faq-wpr{
            //     border-bottom: 0;
            // }

            // gap: 77px;
            .txt-menu {
                font-weight: 700;
                font-size: 1.8rem;
                line-height: calc(27 / 18);
                letter-spacing: .05em;
            }

            @include mq("tablet") {
                // display: none !important;
            }
        }

        &--content {
            padding: 14px 15px;
            background: rgba($color: #222, $alpha: .1);

            @include mq("tablet") {
                padding: 0;
                background: none;
            }

            &.active-content {
                opacity: 1;
                max-height: 100%;
                visibility: visible;
                transition: all .2s ease;
            }
        }
    }

    .contact {
        margin-top: 33px;
        padding-block: 0;
        background: none;

        @include mq("tablet") {
            margin-top: 100px;
        }

        &-block {
            background: url(../img/common/dotted-bg.png) no-repeat;
            background-position: center;
            background-size: cover;

            justify-content: space-between;
        }

        &-col {
            padding: 0;

            &:first-child {
                max-width: 600px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 13px 21px;

                @include mq("tablet") {
                    padding: 20px;
                }
            }

            .primary-btn {
                border: none;
                margin-bottom: 0;
            }
        }
    }
}

.faq {
    &-wpr {
        border-bottom: 1px solid rgba($color: #222222, $alpha: .2);

        &-q {
            cursor: pointer;
            transition: all .2s ease;
            z-index: 9;
            position: relative;

            &-content {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                gap: 9.5px;
                padding: 20px 0 20px 0px;
                transition: all .2s ease;

                @include mq("tablet") {
                    align-items: center;
                    padding: 20px 0 21px 4px;
                    gap: 27px;
                }

                @include mq("bootstrap-lg") {
                    padding: 30px 0 31px 4px;
                }

            }

            .q {
                display: block;
                font-weight: 400;
                font-size: 2rem;
                line-height: calc(29 / 20);
                letter-spacing: .1em;

                @include mq("tablet") {
                    font-size: 2.2rem;
                    line-height: 1;
                }

                @include mq("bootstrap-lg") {
                    font-size: 2.8rem;
                }
            }

            .txt {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                gap: 17.5px;

                text-align: start;
                font-weight: 700;
                font-size: 1.6rem;
                line-height: calc(30.4 / 16);
                letter-spacing: .1em;

                @include mq("tablet") {
                    font-size: 1.8rem;
                    line-height: calc(26 / 18);
                    gap: 15px;
                }

                @include mq("bootstrap-lg") {
                    font-size: 2rem;
                    line-height: calc(29 / 20);
                    gap: 23px;
                }
            }

            .acc-btn {
                position: relative;
                display: block;
                background: rgba($color: #222, $alpha: .1);
                width: fit-content;
                // width: 100%;
                // height: 45px;
                height: auto;
                padding: 12.5px 12.5px;
                border-radius: 50px;
                transition: all .2s ease;
                margin-top: 5px;
                pointer-events: none;

                @include mq("tablet") {
                    padding: 22.5px 22.5px;
                    margin-top: 0;
                }

                &::before {
                    visibility: visible;
                    max-height: 100%;
                    opacity: 1;

                    position: absolute;
                    content: '';
                    background: url(../img/faq-section/plus.svg) no-repeat;
                    background-position: center;
                    background-size: cover;
                    width: 8px;
                    height: 8px;
                    top: 8px;
                    left: 9px;
                    transition: all .2s ease;

                    @include mq("tablet") {
                        width: 11px;
                        height: 11px;
                        top: 17px;
                        left: 17px;
                    }
                }

                &::after {
                    visibility: hidden;
                    max-height: 0;
                    opacity: 0;

                    position: absolute;
                    content: '';
                    background: url(../img/faq-section/minus.svg) no-repeat;
                    background-position: center;
                    background-size: cover;
                    width: 8px;
                    height: 2px;
                    top: 12px;
                    left: 9px;
                    transition: all .2s ease;

                    @include mq("tablet") {
                        width: 11px;
                        height: 2px;
                        top: 22.5px;
                        left: 17px;
                    }
                }
            }

            &.active {
                // .faq-wpr-q-content {
                //     padding: 20px 0 20px 0px;

                //     @include mq("tablet") {
                //         padding: 20px 0 20px 4px;
                //     }

                //     @include mq("bootstrap-lg") {
                //         padding: 30px 0 20px 4px;
                //     }

                // }

                .acc-btn {
                    background: $color-yellow;

                    &::before {
                        visibility: hidden;
                        max-height: 0;
                        opacity: 0;
                    }

                    &::after {
                        visibility: visible;
                        max-height: 100%;
                        opacity: 1;
                    }
                }
            }
        }

        &-a {
            opacity: 0;
            max-height: 0;
            visibility: hidden;
            transition: all .2s ease;

            &.active-content {
                opacity: 1;
                max-height: 100%;
                visibility: visible;
                transition: all .2s ease;
            }

            &-content {
                background: #FAFAFA;
                padding: 22px 20px 21px;
                margin-bottom: 20px;

                @include mq("tablet") {
                    padding: 30px 40px 34px;
                    margin-bottom: 20px;
                }
            }
        }

        &-card {
            p {
                font-size: 1.6rem;
                line-height: calc(29 / 16);
                letter-spacing: .05em;

                @include mq("tablet") {
                    line-height: calc(30 / 16);
                }
            }
        }
    }
}