.notice {
    &-wrapper {
        background-image: url(".././img/common/dotted-bg.png");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }

    &-details {
        border-left: 2px solid $color-yellow;
        background-color: #fff;
        margin: 23px 0 50px;
        padding: 40px 20px;

        @include mq("tablet") {
            padding: 60px 100px;
            margin: 77px 0 124px;
        }

        &-ttl {
            color: $color-black;
            font-weight: 400;
            font-size: 2rem;
            line-height: calc(29 / 20);
            letter-spacing: 0.05em;

            @include mq("tablet") {
                font-size: 3.2rem;
                line-height: calc(46 / 32);
            }
        }

        &-head {
            border-bottom: 1px solid #707070;
            padding-bottom: 20px;

            @include mq("tablet") {
                padding-bottom: 30px;
            }
        }

        &-dates {
            margin-block: 20px;
            display: flex;
            gap: 15px;

            @include mq("tablet") {
                gap: 34px;
            }

            &-txt {
                font-weight: 400;
                letter-spacing: 0.05em;
                font-size: 1.6rem;
                line-height: calc(24 / 16);
            }
        }

        &-txt {
            font-size: 1.6rem;
            font-weight: 400;
            letter-spacing: 0.04em;
            line-height: calc(26 / 16);

            @include mq("tablet") {
                line-height: calc(31 / 16);
            }
        }
    }

    &-btn-wrapper {
        padding-bottom: 60px;
        @include mq("tablet") {
            padding-bottom: 124px;
        }
        @include mq("tablet", "max") {
            max-width: 260px;
            margin: 0 auto;
        }
    }

    &-btm-btns {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 20px;
        margin-top: 54px;
        align-items: flex-start;

        @include mq("tablet") {
            align-items: center;
            margin-inline: 0;
            margin-top: 124px;
            gap: 40px;
            flex-direction: row;
        }
    }
}
