// font-family
$font-primary: "Noto Sans JP", sans-serif;
$font-sec: "Marcellus", serif;

// colors
$color-primary: #222222;
$color-white: #ffffff;
$color-black: #000;
$color-black-01: #444444;
$color-yellow: #FFE100;
$color-lgray: #E2E2E2;
$color-gray: #F2F2F2;