.news {
    color: #222222;
    background-image: url(.././img/common/dotted-bg.png);
    background-size: cover;
    background-repeat: no-repeat;

    &-inner {
        margin-top: 21px;

        @media (min-width: 768px) {
            margin-top: 51px;
        }
    }

    &-table {
        background-color: #fff;
        margin-inline: auto;
        width: 100%;

        padding-bottom: 20px;

        @media (min-width: 992px) {
            padding-bottom: 37.4px;
        }
    }

    &-titles {
        display: flex;
    }

    &-item {
        font-weight: 500;
        font-size: 1.6rem;
        line-height: calc(24 / 16);
        letter-spacing: .1em;
        transition: all .5s ease-in-out;
        width: 100%;
        // max-width: 600px;
        padding: 13px 0;
        margin-inline: auto;
        width: 50%;
        text-align: center;
        background-color: #E2E2E2;
        color: rgba($color: #222222, $alpha: .5);

        @include mq("tablet") {
            font-size: 2rem;
            line-height: calc(29 / 20);
            padding: 20px 0 19px;
        }

        &:hover {
            cursor: pointer;
            color: $color-white;
            background-color: #909090;
        }
    }


    &-content {
        &-item {
            display: none;

            flex-direction: column;
            justify-content: center;
            align-items: flex-start;

            padding: 20px;

            @media (min-width: 768px) {
                padding: 56px 50px 50px;
            }

            &-inner {
                transition: .5s ease-in-out;
                display: flex;
                align-items: center;
                width: 100%;
                color: #222222;
                border-bottom: 1.5px solid rgba($color: #222222, $alpha: .05);

                padding: 22px 0 20px;

                line-height: calc(21/16);
                font-size: 1.6rem;
                font-weight: 400;
                letter-spacing: 0.05rem;
                flex-wrap: wrap;
                row-gap: 9px;

                &:first-child {
                    padding-top: 0;

                    @media (min-width: 768px) {
                        padding-top: 26px;
                    }
                }

                @media (min-width: 768px) {
                    row-gap: unset;
                    padding: 26px 0 25px;
                    flex-wrap: unset;
                }

                &:hover {

                    // background-color: rgba($color: #222222, $alpha: .10);
                    .l-txt {
                        text-decoration: underline;
                    }
                }
            }

            &-date {
                // max-width: 83px;
            }

            .s-txt {
                font-weight: 500;
                text-align: center;
                display: inline-block;
                width: 100%;
                max-width: 122.3px;

                @media (min-width: 768px) {
                    border-right: 1px solid rgba($color: #000, $alpha: .2);
                    // border-right: 1px solid #000;
                    margin-right: 17.7px;
                }

                // display: inline-flex;

                // &::after {
                //     @media (min-width: 768px) {
                //         content: " ";
                //         display: inline-block;
                //         height: 20px;
                //         width: 1px;
                //         margin-right: 17.7px;
                //         background-color: #000;
                //     }
                // }
            }

            .l-txt {
                align-items: center;
                width: 96%;

                @media (min-width: 768px) {
                    width: unset;
                }

                .news-arrow {
                    position: relative;

                    span {
                        position: absolute;
                        right: -20.55px;
                        top: 50%;
                        transform: translatey(-50%);

                        &:after {
                            content: " ";
                            display: block;
                            width: 10px;
                            height: 8px;

                            background-image: url(../../assets/img/news/arrow.svg);
                            background-size: cover;
                            background-repeat: no-repeat;
                        }
                    }
                }

                .news-pdf {
                    position: relative;

                    span {
                        position: absolute;
                        right: -25.125px;
                        top: 6px;

                        &:after {
                            content: " ";
                            display: block;
                            width: 15.13px;
                            height: 19.25px;

                            background-image: url(../../assets/img/news/pdf.svg);
                            background-size: cover;
                            background-repeat: no-repeat;
                        }
                    }
                }
            }
        }

        &-bottom {
            display: flex;
            justify-content: center;
            align-items: center;
            width: fit-content;
            margin-inline: auto;

            gap: 15px;

            @media (min-width: 768px) {
                gap: 20px;
            }

            &:hover {
                cursor: pointer;

                .company-card-arrow-normal {
                    display: none;
                }

                .company-card-arrow-hover {
                    display: block;
                }
            }

            // margin: 19.6px 0 20px;

            // @media (min-width: 992px) {
            //     margin: 50px 0;
            // }

            &-txt {
                font-size: Noto Sans JP Regular;
                letter-spacing: 0.1em;
                font-weight: 400;

                font-size: 1.8rem;
                line-height: calc(24/18);

                @media (min-width: 768px) {
                    font-size: 2rem;
                    line-height: calc(27/20);
                }

            }
        }
    }

    .primary-btn {
        width: fit-content;
        margin-inline: auto;
        gap: 15px;
        // padding: 14px 0;
        padding: 0;
        border: none;

        @include mq("tablet") {
            gap: 20px;
            // padding: 19.5px 0;
        }

        &--icon {
            padding: 11.5px 12.5px;

            @include mq("tablet") {
                padding: 14px 15.5px;
            }

            img {
                width: 10px;
                height: 12px;

                @include mq("tablet") {
                    width: 14px;
                    height: 17px;
                }
            }
        }

        span {
            font-size: 1.8rem;
            line-height: calc(26 / 18);

            @include mq("bootstrap-lg") {
                font-size: 2rem;
                line-height: calc(29 / 20);
            }
        }
    }
}

.clicked-news {
    background-color: #fff;
    color: $color-primary;

    transition: .1s ease-in-out;
}