.breadcrumb {
    overflow-x: scroll;
    overflow-y: hidden;
    padding-top: 68px;
    margin-inline: 15px auto;
    @include mq("tablet"){
        overflow-x: unset;
        padding-top: 101px;
        margin-inline: 97px auto;
    }

    &__heading{
        display: flex;
        align-items: center;
        white-space: nowrap;
    }

    &__title,
    &__subtitle {
        font-size: 1.6rem;
        line-height: calc(24 / 15);
        letter-spacing: .05em;
    }

    &__title{
        color: $color-primary;
        transition: all .2s ease-in;
        text-decoration: none;
        &:hover{
            opacity: .7;
        }
    }
    span{
        display: inline-block;
        margin-inline: 7px;
    }
}