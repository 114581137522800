html {
    font-size: 10px;
    //for footer position bottom

    // height: 100%;
}

body {
    font-family: $font-primary;
    font-size: 1.6rem;
    font-weight: 400;
    color: $color-primary;
    line-height: 1.2;
    background: $color-white;

    //for footer position bottom

    // display: flex;
    // flex-direction: column;
    // min-height: 100%;
}

.container {
    display: block;
    max-width: 1240px;
    padding: 0 20px;
    margin: 0 auto;
}

.container-md {
    display: block;
    max-width: 1140px;
    padding: 0 20px;
    margin: 0 auto;
}

.container-sm {
    display: block;
    max-width: 940px;
    padding: 0 20px;
    margin: 0 auto;
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

ul {
    li {
        list-style-type: none;
    }
}

img {
    display: block;
}

a {
    text-decoration: none;
    transition: all .5s ease;
}

%reset-ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

* {
    box-sizing: border-box;
}