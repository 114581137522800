.business {
    &-content {
        .section {
            padding-block: 60px;

            @include mq("tablet") {
                padding-block: 80px;
            }

            @include mq("bootstrap-lg") {
                padding-block: 100px;
            }
        }

        .profile-btm {
            .section {
                padding-bottom: 120px;
    
                @include mq("tablet") {
                    padding-bottom: 80px;
                }
    
                @include mq("bootstrap-lg") {
                    padding-bottom: 100px;
                }
            }
            table {
                tr {
                    &:first-child {
                        td {
                            padding-top: 10px;

                            @include mq("tablet") {
                                padding-top: 17px;
                            }
                        }
                    }

                    &:last-child {

                        th,
                        td {
                            padding-bottom: 11px;
                            @include mq("tablet") {
                            }
                        }
                    }

                    th,
                    td {
                        display: table-cell;
                    }

                    th {
                        vertical-align: middle;
                        font-weight: 500;
                        font-size: 1.6rem;
                        line-height: calc(24 / 16);
                        letter-spacing: .05em;
                        width: 42px;
                        padding: 10px 20px 10px 12px;

                        @include mq("tablet") {
                            font-size: 2rem;
                            line-height: calc(27.9 / 20);
                            letter-spacing: .05em;
                            width: 90px;
                            padding: 10px 25.5px 10px 34px;
                        }
                    }

                    td {
                        font-weight: 700;
                        font-size: 1.8rem;
                        line-height: calc(26 / 18);
                        letter-spacing: .05em;
                        padding: 15px 18px 10px 0;

                        @include mq("tablet") {
                            font-size: 2rem;
                            line-height: calc(27.9 / 20);
                            letter-spacing: .05em;
                            padding: 22px 0 22px;
                        }

                        span {
                            font-weight: 400;
                            font-size: 1.6rem;
                            line-height: calc(26 / 16);
                            display: block;
                            @include mq("tablet") {
                                font-size: 1.8rem;
                                line-height: calc(26 / 18);
                            }
                        }
                    }
                }
            }
        }
    }

    &-wpr {
        display: flex;
        flex-direction: column;
        gap: 20px;

        @include mq("tablet") {
            gap: 60px;
        }

        p {
            font-size: 1.6rem;
            line-height: calc(26 / 16);
            letter-spacing: .05em;

            @include mq("tablet") {
                line-height: calc(31.5 / 16);
            }
        }
    }
}