.group {
    background: url(../img/group/group-bg.png) no-repeat;
    background-position: center;
    background-size: cover;

    .section-ttl {
        img {
            display: inline-block;
            max-width: 174px;
            margin-left: 10px;
            @include mq("tablet") {
                max-width: 259px;
                margin-left: 20px;
            }
        }
    }

    &-block {
        &--txt {
            font-size: 1.6rem;
            line-height: calc(27.7 / 16);
            letter-spacing: .05rem;
            margin-top: 21px;
            margin-bottom: 30px;


            @include mq("tablet") {
                font-size: 1.8rem;
                line-height: calc(31.5 / 18);
                margin-bottom: 40px;
                margin-top: 41.3px;
            }
            span{
                display: inline-block;
            }
            img{
                max-width: 140px;
                margin-inline: 0 13px;
                margin-top: 16px;
                display: inline-block;
                @include mq("tablet") {
                    max-width: 165px;
                    margin-inline: 11px;
                    margin-top: 0;
                }
            }

            a {
                color: $color-primary;
                text-decoration: underline;
            }
        }
    }

    &-row {
        display: flex;
        align-items: center;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 15px;

        @include mq("tablet") {
            flex-direction: row;
            gap: 20px 15px;
        }

        @include mq("desktop") {
            gap: 20px 24px;
        }
    }

    .primary-btn {
        width: 100%;
        background: $color-white;

        @include mq("tablet") {
            width: calc((100% / 2) - (15px / 2));
        }

        @include mq("bootstrap-lg") {
            width: calc((100% / 3) - (15px * 2) / 3);
        }

        @include mq("desktop") {
            width: calc((100% / 3) - (24px * 2) / 3);
        }
    }
}