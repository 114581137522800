.faq {
    .banner-nav {
        background-image: url(.././img/common/dotted-bg.png);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;

        grid-template-rows: repeat(3, 1fr);
        transition: all .3s ease;

        @include mq("tablet") {
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: unset;
        }
    }

    .services {
        .primary-heading {
            margin-bottom: 34px;

            @include mq("tablet") {
                margin-bottom: 80px;
            }
        }

        .primary-btn {
            margin-inline: auto;
            width: fit-content;
            // max-width: 700px;
            margin-top: 13px;
            padding: 20.2px 18px 21px 24px;
            gap: 16px;
            border: none;
            box-shadow: 0 5px 15px rgba($color: #000000, $alpha: .08);

            @include mq("tablet") {
                margin-top: 31px;
                padding: 15.5px 15px 15.5px 76px;
                gap: 70px;
            }

            .faq-btn {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                gap: 6.1px;

                @include mq("tablet") {
                    flex-direction: row;
                    align-items: center;
                    gap: 22px;
                }

                &--img {
                    width: 100%;
                    max-width: 242px;

                    @include mq("tablet") {
                        max-width: 340px;
                    }
                }

                span {
                    font-weight: 700;
                    font-size: 1.6rem;
                    line-height: calc(24 / 16);
                    letter-spacing: .1em;

                    @include mq("tablet") {
                        font-size: 2rem;
                        line-height: calc(29 / 20);
                    }
                }
            }
        }

        .faq-wpr-card {
            &:first-child {
                h4 {
                    margin-top: 0;
                }
            }

            h4 {
                font-weight: 700;
                font-size: 1.6rem;
                line-height: calc(30 / 16);
                letter-spacing: .05em;
                margin-top: 29px;

            }

            p {
                font-size: 1.6rem;
                line-height: calc(29 / 16);
                letter-spacing: .05em;

                @include mq("tablet") {
                    line-height: calc(30 / 16);
                }
            }
        }

        .faq-wpr--link {
            color: $color-primary;
            text-decoration: underline;
            transition: all .3s ease;

            &:hover {
                opacity: .7;
            }
        }
    }

    .brokerage {
        .primary-heading {
            margin-bottom: 24px;

            @include mq("tablet") {
                margin-bottom: 40px;
            }
        }

        &.section {
            padding-top: 9px;

            @include mq("tablet") {
                padding-top: 30px;
            }
        }

        .faq-wpr {
            &:first-child {
                border-top: 1px solid rgba($color: #222222, $alpha: .2);
            }
        }

        .faq-wpr-q-content {
            transition: all .3s ease;
            padding-block: 24px 20px;
            align-items: center;

            @include mq("tablet") {
                padding-block: 28px;
            }

            &.active {
                padding-block: 20px 20px;
                transition: all .3s ease;

                @include mq("tablet") {
                    padding-block: 28px;
                }
            }

            .txt {
                @include mq("tablet", "max") {
                    line-height: calc(28 / 16);
                    letter-spacing: .05em;
                }
            }
        }

        .faq-wpr-a-content {
            transition: all .3s ease;
            padding: 21.5px 20px 20.5px 20px;

            @include mq("tablet") {
                padding: 30px 34px;
            }

            .faq-wpr-card p {
                @include mq("tablet", "max") {
                    line-height: calc(28 / 16);
                }
            }
        }
    }

    .docs {
        background: url(.././img/common/dotted-bg.png) no-repeat;
        background-position: center;
        background-size: cover;

        .primary-heading {
            margin-bottom: 24px;

            @include mq("tablet") {
                margin-bottom: 40px;
            }
        }

        &-row {
            background: $color-white;
            border-left: 2px solid $color-yellow;
            border-bottom: 1px solid rgba($color: #222, $alpha: .5);
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            justify-content: space-between;
            gap: 9.5px;
            padding: 20px;
            &:last-child{
                border-bottom: none;
            }

            @include mq("tablet") {
                flex-direction: row;
                gap: 40px;
                padding: 30px 30px 30px 40px;
            }
        }

        &-col {
            width: 100%;
            display: flex;
            align-items: flex-start;
            gap: 10px;
            flex-direction: column;

            @include mq("tablet") {
                flex-direction: row;
                gap: 40px;
            }

            &--num {
                font-weight: 700;
                font-size: 1.6rem;
                line-height: calc(24 / 16);
                letter-spacing: .05em;
            }

            &--ttl {
                font-weight: 700;
                font-size: 1.6rem;
                line-height: calc(24 / 16);
                letter-spacing: .05em;
                margin-bottom: 3px;

                @include mq("tablet") {
                    margin-bottom: 8px;
                }
            }

            &--desc {
                font-size: 1.6rem;
                line-height: calc(25.5 / 16);
                letter-spacing: .05em;

                @include mq("tablet") {
                    line-height: calc(27 / 16);
                }
            }

            &.-btm {
                width: 100%;
                max-width: 110px;
                margin-inline: auto 0;
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 11px;

                &:hover {
                    opacity: .7;
                }
            }

            &--link {
                color: $color-primary;
                font-weight: 700;
                font-size: 1.6rem;
                line-height: calc(24 / 16);
                letter-spacing: .05em;
            }

            &--img {
                width: 15px;
            }
        }
    }

    .contact {

        // margin-top: 30px;
        padding-block: 30px;
        background: none;

        @include mq("tablet") {
            padding-block: 100px;
        }

        &-block {
            background: url(../img/common/dotted-bg.png) no-repeat;
            background-position: center;
            background-size: cover;

            justify-content: space-between;
        }

        &-col {
            padding: 0;

            &:first-child {
                max-width: 600px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 13px 21px;

                @include mq("tablet") {
                    padding: 20px;
                }
            }

            .primary-btn {
                border: none;
                margin-bottom: 0;
            }
        }

    }
}