.news {
    &-wrapper {
        background-image: url(".././img/common/dotted-bg.png");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        padding-bottom: 20px;

        @include mq("tablet") {
            padding-bottom: 120px;
        }

        .pagetitlebar__subtitle {
            padding: 0 20px;

            @include mq("tablet") {
                padding: 0;
            }
        }

        .news-table {
            padding-bottom: 0;
        }

        .news-inner {
            margin-top: 0;
            border-left: 2px solid $color-yellow;
            margin-top: 36px;

            @include mq("tablet") {
                margin-top: 0;
            }
        }

        .news-content-item {
            padding: 24px 20px 30px;

            @include mq("tablet") {
                padding: 30px 100px 49px;
            }
        }

        .news-content-item .l-txt {
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            width: 86%;

            @include mq("tablet") {
                width: 45%;
            }

            @include mq("bootstrap-lg") {
                width: 60%;
            }

            @include mq("desktop") {
                width: 86%;
            }
        }

        .news-content-item-inner {
            border-bottom: 1px solid rgba($color: #222, $alpha: .5);
            position: relative;
            padding-block: 30px;
            row-gap: 7px;

            @include mq("tablet") {
                padding-block: 25px;
                row-gap: unset;
            }

            &:not(:first-child) {
                @include mq("tablet", "max") {
                    .arrow {
                        @include mq("tablet", "max") {
                            position: absolute;
                            right: 0;
                            top: 65%;
                            transform: translateY(-50%);
                        }
                    }
                }
            }

            @include mq("tablet", "max") {
                .date {
                    position: relative;

                    &::after {
                        content: "";
                        width: 1px;
                        height: 20px;
                        position: absolute;
                        display: block;
                        background-color: #000000;
                        right: -15px;
                        top: 0;
                    }
                }
            }

            .arrow {
                @include mq("tablet", "max") {
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }

                &::after {
                    content: "";
                    display: block;
                    width: 12px;
                    height: 10px;
                    background-image: url(.././img/news/arrow.svg);
                    background-size: cover;
                    background-repeat: no-repeat;
                    right: 0;
                    top: 50%;
                    position: absolute;
                    transform: translateY(-50%);

                    @include mq("tablet") {
                        right: 25px;
                        top: 50%;
                    }
                }
            }

            &.pdf {
                .arrow {
                    &::after {
                        content: "";
                        width: 18px;
                        height: 16px;
                        background-image: url(.././img/news/pdf-news.svg);
                    }
                }
            }

            &.box {
                .arrow {
                    &::after {
                        content: " ";
                        width: 11px;
                        height: 11px;
                        background-image: url(.././img/news/box.svg);
                    }
                }
            }
        }
    }

    &-pagination {
        padding: 102px 0 60px;

        @include mq("tablet") {
            padding: 90px 0;
        }
    }
}