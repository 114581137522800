.webservices-section {
    .container {
        display: block;
        max-width: 100%;
        padding: 0 20px;
        margin: 0 auto;
        max-width: 1140px;

        &-lg {
            max-width: 1366px;
        }

        &-md {
            max-width: 1346px;
            margin: 0 auto;
            padding: 0 20px;
        }
    }

    .breadcrumb {
        overflow-x: scroll;
        overflow-y: hidden;
        padding-top: 68px;
        margin-inline: 15px auto;
        position: absolute;

        @media (min-width: 768px) {
            overflow-x: unset;
            padding-top: 101px;
            margin-inline: 97px auto;
        }

        &__heading {
            display: flex;
            align-items: center;
            white-space: nowrap;
        }

        &__title,
        &__subtitle {
            font-size: 16px;
            line-height: calc(24 / 15);
            letter-spacing: .05em;
            font-weight: 400;
        }

        &__title {
            color: #222;
            transition: all .2s ease-in;
            text-decoration: none;

            &:hover {
                opacity: .7;
            }
        }

        span {
            display: inline-block;
            margin-inline: 7px;
        }
    }

    .section {
        padding-block: 70px;
        width: 100%;
        margin-inline: auto;

        @media (min-width: 768px) {
            padding-block: 100px;
        }

        &-header {
            text-align: center;
            margin-bottom: 20px;

            @media (min-width: 768px) {
                margin-bottom: 40px;
            }

            position: relative;

            &::after {
                position: absolute;
                content: '';
                background: rgba($color: #222, $alpha: .5);
                height: 30px;
                width: 1px;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
            }

            // &-main {
            //     display: block;
            //     font-size: 16px;
            //     line-height: 24px;

            //     @media (min-width: 768px) {
            //         font-size: 18px;
            //         line-height: 26px;
            //     }
            // }

            &-sub {
                font-weight: 400;
                display: block;
                font-size: 24px;
                line-height: 39.5px;
                padding-bottom: 54px;

                @media (min-width: 768px) {
                    font-size: 35px;
                    line-height: 55px;
                    padding-bottom: 60px;
                }

                @media (min-width: 992px) {
                    font-size: 40px;
                    line-height: 58px;
                }
            }
        }
    }

    .c-white {
        color: #FFF;
    }

    .c-primary {
        color: #222 !important;

        &::after {
            border-top: 5px solid #222 !important;
        }
    }

    .btn {
        width: fit-content;
        margin-inline: auto;
        border-radius: 10px;
        border: 1px solid #000;
        transition: all .25s ease;

        display: flex;
        align-items: baseline;
        justify-content: center;
        gap: 20px;

        padding: 26px 21px 26px 29px;
        color: #000;

        @media (min-width:768px) {
            padding: 25px 114px 25px 130px;
        }

        &:hover {
            background: rgb(0, 0, 0, .1);
        }

        span {
            font-size: 16px;
            line-height: 24px;

            @media (min-width:768px) {
                font-size: 18px;
                line-height: 26px;
            }
        }

        img {
            width: 19px;
            height: 15px;
        }
    }

    .register {
        &-block {
            display: block;
            align-items: center;
            width: 100%;

            @media (min-width:768px) {
                display: flex;
                margin-top: -1px;
            }
        }

        &-wpr {
            background: url(../img/register/register-wpr-bg-sp.jpg) no-repeat;
            background-position: center;
            background-size: cover;
            text-align: center;
            width: 100%;
            padding: 33px 20px 48px;

            @media (min-width:768px) {
                background: url(../img/register/register-wpr-bg.jpg) no-repeat;
                background-position: center;
                background-size: cover;
                padding: 10px;
            }

            @media (min-width:992px) {
                padding: 21px 20px;
            }

            @media (min-width:1300px) {
                padding: 34px 36px 32px 32px;
            }

            &--ttl {
                font-weight: 500;
                font-size: 24px;
                line-height: 35px;
                margin-bottom: 22px;

                @media (min-width:768px) {
                    font-size: 20px;
                    margin-bottom: 20px;
                }

                @media (min-width:992px) {
                    font-size: 22px;
                    line-height: 40px;
                }

                @media (min-width:1300px) {
                    font-size: 28px;
                }
            }
        }

        &-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            gap: 20px;

            @media (min-width:768px) {
                flex-direction: row;
                gap: 10px;
            }

            @media (min-width:992px) {
                gap: 20px;
            }

            @media (min-width:1300px) {
                gap: 30px;
            }

        }

        &-card {
            display: block;
            width: 100%;
            height: 100%;
            background-color: #FFF;
            color: #000;
            position: relative;
            transition: all .25s ease;

            padding: 17px 18px 14px 17px;
            min-height: 155px;

            @media (min-width:768px) {
                padding: 10px;
                min-height: 160px;
            }

            @media (min-width:1300px) {
                padding: 20px 20px 16px;
            }

            &:hover {
                background-color: rgba(255, 255, 255, .8);

                .register-card--img {
                    background: #FFE102;
                }
            }

            &--img {
                position: absolute;
                padding: 11.5px 13px;
                border-radius: 50%;
                bottom: 11px;
                right: 20px;
                background-color: rgba($color: #222, $alpha: .1);
                transform: rotate(270deg);
                transition: all .25s ease;

                img {
                    width: 9px;
                    height: 12px;
                    transition: all .25s ease;
                }
            }

            &--ttl {
                text-align: start;
                font-weight: 500;
                letter-spacing: 0;
                font-size: 20px;
                line-height: 28.5px;
                padding-bottom: 16px;
                margin-bottom: 10px;
                padding-inline: 0;
                border-bottom: 1px solid #8B8B8B;

                @media (min-width:768px) {
                    font-size: 16px;
                    line-height: 22px;
                    padding-bottom: 16px;
                    margin-bottom: 17px;
                }

                @media (min-width:992px) {
                    font-size: 18px;
                    line-height: 26px;
                }

                @media (min-width:1300px) {
                    font-size: 20px;
                    line-height: 30px;
                }

                @media (min-width:1366px) {
                    padding-inline: 10px;
                    font-size: 22px;
                }
            }

            &--desc {
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                text-align: start;
                padding-left: 0px;

                @media (min-width:768px) {
                    font-size: 15px;
                    line-height: 22px;
                    padding-left: 24px;
                }

                @media (min-width:992px) {
                    font-size: 18px;
                    line-height: 26px;
                }

                &.ml--20 {
                    margin-left: 0;

                    @media (min-width:1300px) {
                        margin-left: -20px;
                    }
                }

                &.u-pt15 {
                    padding-top: 15px;
                    padding-left: 4px;

                    @media (min-width:768px) {
                        padding-top: 0;
                    }
                }
            }
        }

        &-box {
            background: url(../img/register/register-box-bg.jpg) no-repeat;
            background-position: center;
            background-size: cover;
            width: 100%;
            max-width: 100%;
            text-align: center;
            padding: 33px 20px 22px;

            @media (min-width:768px) {
                padding: 10px;
                max-width: 240px;
            }

            @media (min-width:992px) {
                padding: 20px;
                padding: 22px 20px;
                max-width: 320px;
            }

            @media (min-width:1300px) {
                padding: 34px 32px 32px 36px;
                max-width: 468px;
            }

            .register-card {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 10px;
                transition: all .25s ease;

                @media (min-width:768px) {
                    gap: 15px;
                }

                &:hover {
                    img {
                        transform: scale(1.1);
                        transition: all .25s ease;
                    }
                }

                span {
                    font-weight: 500;
                    font-size: 28px;
                    line-height: 46px;

                    @media (min-width:768px) {
                        font-size: 22px;
                        line-height: 32px;
                    }

                    @media (min-width:992px) {
                        font-size: 35px;
                        line-height: 46px;
                    }
                }

                img {
                    width: 35px;
                    margin-bottom: -5px;
                    transition: all .25s ease;
                }
            }
        }
    }

    //ABOUT SECTION
    .about {
        &.section {
            padding-top: 50px;

            @media (min-width:768px) {
                padding-top: 100px;
            }
        }

        &-block {
            &--txt {
                font-weight: 400;
                text-align: left;
                font-size: 16px;
                line-height: 28px;
                margin-bottom: 60px;
                margin-top: 0;

                @media (min-width: 768px) {
                    text-align: center;
                    font-size: 18px;
                    line-height: 31.5px;
                    margin-bottom: 50px;
                    // margin-top: -30px;
                }
            }

            &--list {
                // color: #FFE100;
                font-size: 12px;
                line-height: 20px;
                font-weight: 500;
                letter-spacing: 0.009em;

                @media (min-width: 768px) {
                    letter-spacing: normal;
                }
            }
        }

        &-content {
            border: 7px solid #FFE102;
            border-radius: 10px;

            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 30px;
            width: fit-content;
            margin-inline: auto;

            @media (min-width:768px) {
                flex-direction: row;
                min-width: 661px;
            }

            @media (min-width:992px) {
                flex-direction: row;
                margin-inline: 0 auto;
                width: 100%;
            }

            &:last-child {
                margin-bottom: 40px;
            }

            // SECOND ABOUT CONTENT 
            &.-scd {
                border: 5px solid #FFE102;

                .about-wpr {
                    &.-rgt {
                        flex-wrap: wrap;
                        gap: 22px;
                        padding: 22px 20px 17px;

                        @media (min-width:768px) {
                            gap: 4px;
                            padding: 5px 30px;
                        }

                        @media (min-width:992px) {
                            gap: 20px 40px;
                            padding: 20px;
                        }

                        @media (min-width:1280px) {
                            gap: 20px 59px;
                            padding: 25px 30px 25px 44px;
                        }

                        .fs-12 {
                            font-size: 12px;
                        }
                    }
                }

                .about-card {

                    width: 100%;

                    @media (min-width:992px) {
                        width: calc((100% / 2) - (40px / 2));
                    }

                    @media (min-width:1280px) {
                        width: calc((100% / 2) - (59px / 2));
                    }
                }
            }

            // THIRD ABOUT CONTENT
            &.-lst {
                width: fit-content;
                border: 3px solid #C1C1C1;

                .about-wpr {
                    &.-rgt {
                        padding: 22px 23px 19px;

                        @media (min-width:768px) {
                            padding: 10px 84px 10px 30px;
                        }

                        @media (min-width:992px) {
                            padding: 20px;
                        }

                        @media (min-width:1280px) {
                            padding: 49px 80px 49px 44px;
                        }

                    }
                }
            }
        }

        &-wpr {
            width: 100%;
            height: fit-content;

            &.-lft {
                position: relative;
                max-width: 336px;

                @media (min-width: 768px) {
                    max-width: 350px;
                }
            }

            // &--flex {
            //     display: flex;
            //     flex-direction: column;
            //     gap: 20px;
            // }

            &--img {
                border-top-right-radius: 6px;
                border-top-left-radius: 6px;
                border-bottom-left-radius: 0;
                max-width: 338px;

                @media (min-width:768px) {
                    max-width: 350px;
                    border-top-right-radius: 0;
                    border-bottom-left-radius: 5px;
                    border-top-left-radius: 5px;
                }
            }

            &--ttl {
                font-weight: 700;
                width: 100%;
                text-align: center;
                position: absolute;
                color: #FFF;
                font-size: 24px;
                line-height: 35px;

                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            &.-rgt {
                display: flex;
                flex-direction: column;
                // align-items: center;
                gap: 22px;
                padding: 22px 18px 15px;

                // max-width: 720px;
                @media (min-width:768px) {
                    gap: 5px;
                    padding: 5px 81px 5px 30px;
                }

                @media (min-width:992px) {
                    flex-direction: row;
                    gap: 40px;
                    padding: 20px;
                }

                @media (min-width:1280px) {
                    gap: 116px;
                    padding: 45px 44px;
                }
            }
        }

        &-card {
            display: flex;
            align-items: center;
            border-bottom: 1px solid #D1CCB9;

            gap: 12px;
            padding-bottom: 22px;

            @media (min-width:768px) {
                border-bottom: none;
                padding-bottom: 0;
            }

            &.bd-none {
                border-bottom: none;
                padding-bottom: 0;
            }

            &--img {
                width: 62px;
                height: 62px;

                @media (min-width:768px) {
                    width: 36px;
                    height: 36px;
                }

                @media (min-width:992px) {
                    width: 42px;
                    height: 42px;
                }

                @media (min-width:1280px) {
                    width: 62px;
                    height: 62px;
                }
            }

            &--desc {
                font-weight: 500;
                font-size: 24px;
                line-height: 35px;
                letter-spacing: .05em;

                @media (min-width:768px) {
                    font-size: 15px;
                    line-height: 21px;
                }

                @media (min-width:992px) {
                    font-size: 24px;
                    line-height: 36px;
                }

                @media (min-width:1280px) {
                    font-size: 24px;
                    line-height: 35px;
                }

                span {
                    font-weight: 400;
                    display: block;
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: .05em;

                    @media (min-width:768px) {
                        font-size: 12px;
                        line-height: 21px;
                    }

                    @media (min-width:992px) {
                        font-size: 16px;
                        line-height: 22px;
                    }

                    @media (min-width:1280px) {
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
            }

            &--btn {
                transition: all .25s ease;
                border-radius: 10px;
                width: fit-content;
                display: flex;
                align-items: center;
                gap: 20px;

                color: #000;
                border: 1px solid #000;
                padding: 13px 19px 12px 26px;
                margin-bottom: 10px;

                &:hover {
                    border: 1px solid #FFE102;
                    // background: rgb(0, 0, 0, .05);
                    background: #FFE102;
                }

                @media (min-width:768px) {
                    padding: 5px 10px;
                    gap: 5px;
                    margin-bottom: 0;
                }

                @media (min-width:992px) {
                    padding: 10px;
                    gap: 5px;
                }

                @media (min-width:1280px) {
                    padding: 9px 19px 9px 27px;
                    gap: 20px;
                }

                span {
                    font-size: 18px;
                    line-height: 26px;
                    margin-left: 0;

                    @media (min-width:768px) {
                        font-size: 14px;
                        line-height: 22px;
                    }

                    @media (min-width:992px) {
                        font-size: 18px;
                        line-height: 26px;
                        margin-left: 8px;
                    }
                }
            }
        }

        &-btn {
            &--img {
                width: 81px;
                height: 51px;

                @media (min-width:768px) {
                    width: 42px;
                    height: 26px;
                }

                @media (min-width:992px) {
                    width: 62px;
                    height: 36px;
                }

                @media (min-width:1280px) {
                    width: 72px;
                    height: 46px;
                }
            }
        }
    }

    //REGISTRATION SECTION
    .registration {
        &.section {
            padding-bottom: 50px;

            @media (min-width:768px) {
                padding-bottom: 100px;
            }
        }

        &-block {
            width: 100%;
            margin-inline: auto;
            background: #FAFAFA;
            padding: 30px 20px;
            // margin-inline: 20px;

            @media (min-width:768px) {
                padding: 20px;
                // margin-inline: 0;
            }

            @media (min-width:992px) {
                padding: 40px 94px 50px 89px;
            }

            &--ttl {
                font-weight: 500;
                max-width: 350px;
                border-bottom: 1px solid #000;
                text-align: center;
                font-size: 22px;
                line-height: 27.5px;
                padding-bottom: 15px;
                margin-bottom: 30px;
                // margin-left: -12px;
                margin-inline: auto;

                @media (min-width:992px) {
                    max-width: 1100px;
                    font-size: 28px;
                    line-height: 42px;
                    padding-bottom: 25px;
                    margin-bottom: 40px;
                }

                @media (min-width:1280px) {
                    font-size: 32px;
                    line-height: 46px;
                }

                span {
                    font-weight: 400;
                    display: block;
                    font-size: 18px;
                    line-height: 26px;
                    margin-top: 10px;
                }
            }

            &.-btm {
                padding-block: 30px;
                margin-top: 30px;
                padding-inline: 0;

                @media (min-width:768px) {
                    padding-block: 20px;
                    margin-top: 50px;
                }

                @media (min-width:992px) {
                    padding-block: 40px 39px;
                }

                .registration {
                    &-row {
                        padding-inline: 20px;

                        @media (min-width:768px) {
                            padding-inline: 20px;
                        }

                        @media (min-width:992px) {
                            padding-inline: 210px;
                        }

                        margin-bottom: 0;
                        gap: 40px;

                        @media (min-width:768px) {
                            gap: 10px;
                        }

                        @media (min-width:992px) {
                            gap: 13px;
                        }
                    }

                    &-col {
                        width: 100%;
                        position: relative;

                        @media (min-width:567px) {
                            width: calc((100% / 2) - (40px / 2));
                        }

                        @media (min-width:768px) {
                            width: calc((100% / 4) - ((10px * 3) / 4));
                        }

                        @media (min-width:992px) {
                            width: calc((100% / 4) - ((13px * 3) / 4));
                        }
                    }
                }
            }
        }

        &-row {
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
            flex-direction: column;
            gap: 40px;
            margin-bottom: 20px;

            @media (min-width:567px) {
                flex-direction: row;
                margin-bottom: 33px;
            }

            @media (min-width:768px) {
                gap: 10px;
            }

            @media (min-width:992px) {
                gap: 14px;
            }
        }

        &-col {
            width: 100%;
            position: relative;

            @media (min-width:567px) {
                width: calc((100% / 2) - (40px / 2));
            }

            @media (min-width:768px) {
                width: calc((100% / 5) - ((10px * 4) / 5));
            }

            @media (min-width:992px) {
                width: calc((100% / 5) - ((14px * 4) / 5));
            }

            &:first-child {
                .registration-col--desc {
                    padding-left: 0;

                    @media (min-width:768px) {
                        padding-left: 14px;
                    }
                }
            }

            &:last-child {
                &::after {
                    content: none;
                }

                .registration-col--top {
                    &::after {
                        content: none;
                    }
                }
            }

            &::after {
                content: '';
                position: absolute;
                background: url(../img/registration/arw-black.svg) no-repeat;
                background-position: center;
                background-size: cover;
                width: 9px;
                height: 16px;
                bottom: -30px;
                transform: translateY(-50%)rotate(90deg);
                right: 50%;

                @media (min-width:768px) {
                    content: none;
                }
            }

            &--img {
                margin-inline: auto;
                max-width: 214px;

                @media (min-width:768px) {
                    max-width: 100%;
                }
            }

            &--top {
                width: fit-content;
                margin-inline: auto;
                position: relative;

                &::after {
                    content: none;
                    position: absolute;
                    background: url(../img/registration/arw-black.svg) no-repeat;
                    background-position: center;
                    background-size: cover;
                    width: 9px;
                    height: 16px;
                    top: 55%;
                    transform: translateX(-50%);
                    right: -15px;

                    @media (min-width:768px) {
                        content: '';
                        right: -10px;
                    }

                    @media (min-width:992px) {
                        right: -15px;
                    }
                }
            }

            &--ttl {
                position: absolute;
                top: 0px;
                left: 6px;

                background-color: #000;
                border-radius: 50px;
                border: 2px solid #FFF;
                color: #FFF;

                font-size: 28px;
                line-height: 40px;
                padding-inline: 12px;

                @media (min-width:768px) {
                    font-size: 24px;
                    line-height: 32px;
                    padding-inline: 9px;
                }

                @media (min-width:992px) {
                    font-size: 28px;
                    line-height: 40px;
                    padding-inline: 12px;
                }
            }

            &--desc {
                font-weight: 400;
                margin-top: 15px;
                font-size: 16px;
                line-height: 24px;
                padding-inline: 0;
                letter-spacing: 0.03em;

                @media (min-width:768px) {
                    font-size: 15px;
                    line-height: 22px;
                    padding-inline: 13px;
                }

                @media (min-width:992px) {
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }
    }


    .btn {
        width: fit-content;
        margin-inline: auto;
        border-radius: 10px;
        border: 1px solid #000;
        transition: all .25s ease;

        display: flex;
        align-items: baseline;
        justify-content: center;
        gap: 20px;

        padding: 26px 21px 26px 29px;
        color: #000;

        @media (min-width:768px) {
            padding: 25px 114px 25px 130px;
        }

        &:hover {
            background: rgb(0, 0, 0, .1);
        }

        span {
            font-size: 16px;
            line-height: 24px;

            @media (min-width:768px) {
                font-size: 18px;
                line-height: 26px;
            }
        }

        img {
            width: 19px;
            height: 15px;
        }
    }

    .section-header {
        text-align: center;
        margin-bottom: 20px;
        position: relative;
    }

    @media (min-width: 768px) {
        .section-header {
            margin-bottom: 40px;
        }
    }

    .section-header::after {
        position: absolute;
        content: "";
        background: rgba(34, 34, 34, 0.5);
        height: 30px;
        width: 1px;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    }

    .section-header-sub {
        font-weight: 400;
        display: block;
        font-size: 24px;
        line-height: 39.5px;
        padding-bottom: 54px;
    }

    @media (min-width: 768px) {
        .section-header-sub {
            font-size: 35px;
            line-height: 55px;
            padding-bottom: 60px;
        }
    }

    @media (min-width: 992px) {
        .section-header-sub {
            font-size: 40px;
            line-height: 58px;
        }
    }

    //HERO SECTION
    .hero {
        // background: url(../img/mv/mv-pc.jpg) no-repeat;
        // background-position: center;
        // background-size: cover;
        padding-top: 0;

        @media (min-width:768px) {
            padding-top: 75px;
        }

        @media (min-width:992px) {
            padding-top: 79px;
        }

        img {
            width: 100%;
        }
    }

    //WEBSERVICES SECTION
    .webservices-banner {
        background-image: url(.././img/common/dotted-bg.png);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;

        &-content {
            max-width: 868px;
            margin-inline: auto;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 29px;
            padding: 12px 0 17px;

            @media (min-width: 768px) {
                flex-direction: row;
                gap: 50px;
                padding: 30px 0;
            }

            @media (min-width: 992px) {
                gap: 120px;
            }
        }

        a {
            width: 100%;
            margin-inline: auto;
            text-decoration: none;
            color: #222;
            font-weight: 400;
            font-size: 18px;
            line-height: 26px;
            padding-bottom: 0;
            position: relative;
            padding-inline: 20px;

            @media (min-width: 768px) {
                width: fit-content;
                padding-inline: 0;
                font-size: 20px;
                line-height: 29px;
                padding-bottom: 25px;
            }

            &:hover {
                opacity: .7;
            }

            &:first-child {
                &::before {
                    content: '';

                    @media (min-width: 768px) {
                        content: none;
                    }
                }
            }

            &:last-child {
                &::before {
                    content: none;

                    @media (min-width: 768px) {
                        content: '';
                    }
                }
            }

            &::after {
                position: absolute;
                content: '';
                background: url(../img/webservices-section/common/arw-down-sm.svg) no-repeat;
                background-position: center;
                background-size: cover;
                width: 13px;
                height: 7px;
                bottom: 50%;
                left: unset;
                right: 27.1px;
                // left: 50%;
                transform: translateX(0%) translateY(50%);

                @media (min-width: 768px) {
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }

            &::before {
                position: absolute;
                content: '';
                background: rgba($color: #222, $alpha: .5);
                width: 100%;
                height: 1px;
                top: unset;
                bottom: -17px;
                left: 0;
                transform: translateY(0);

                @media (min-width: 768px) {
                    width: 1px;
                    height: 49px;
                    top: 50%;
                    left: -25px;
                    transform: translateY(-50%);
                }

                @media (min-width: 992px) {
                    left: -60px;
                }
            }
        }

    }

    //CONTACT SECTION
    .contact {
        &.section {
            padding-bottom: 100px;
        }
    
        &-header {
            font-weight: 500;
            text-align: center;
            margin-bottom: 30px;
            font-size: 32px;
            line-height: 46px;
        }
    
        &-info {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 0;
            margin-bottom: 50px;
    
            @media (min-width:768px) {
                flex-direction: row;
                gap: 10px;
                margin-bottom: 30px;
            }
    
            &--main {
                font-size: 43px;
                line-height: 62px;
                margin-top: -7px;
    
                @media (min-width:768px) {
                    font-size: 70px;
                    line-height: 101px;
                    margin-top: 0;
                }
            }
    
            &--sub {
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
    
                @media (min-width:768px) {
                    font-size: 24px;
                    line-height: 35px;
                }
            }
        }
    
        &--btn {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px;
    
            @media (min-width:768px) {
                flex-direction: row;
                gap: 40px;
            }
    
            .btn {
                font-weight: 500;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 10px;
                max-width: 350px;
                width: 100%;
                padding-inline: 10px;
                .register-card--img {
                    position: relative;
                    bottom: 0;
                    right: 0;
                    img {
                        width: 9px;
                        height: 12px;
                        transition: all .25s ease;
                    }
                }
    
                @media (min-width:768px) {
                    max-width: 530px;
                }
    
                @media (min-width:1280px) {
                    padding-inline: 120px 99px;
                }
    
                &:last-child {
                    align-items: center;
                }
    
                &:hover {
                    background: none;
    
                    .register-card--img {
                        background: #FFE100;
                    }
                }
                span{
                    display: block;
                }
            }
    
            .pdf-img {
                width: 20px;
                height: 25px;
            }
        }
    }
}