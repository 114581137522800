.profile {
    &-top {
        &--ttl {
            text-align: center;
            font-weight: 700;
            font-size: 1.6rem;
            line-height: calc(27 / 16);
            letter-spacing: .1em;
            margin-bottom: 20px;

            @include mq("tablet") {
                font-size: 2.8rem;
                line-height: calc(48 / 28);
                margin-bottom: 35px;
            }
        }

        &--desc {
            // text-align: center;
            font-size: 1.5rem;
            line-height: calc(27.86 / 15);
            letter-spacing: .05em;
            margin-top: 28px;

            @include mq("tablet") {
                font-size: 1.6rem;
                line-height: calc(31.6 / 16);
                margin-top: 60px;
            }
        }

        &--note {
            text-align: end;
            font-weight: 700;
            font-size: 1.6rem;
            line-height: calc(24 / 16);
            letter-spacing: .05em;
            margin-top: 20px;

            span {
                display: inline-block;
                font-size: 2rem;
                line-height: calc(29 / 20);
                margin-top: 3px;
                margin-left: 17px;
            }

            @include mq("tablet") {
                margin-top: 30px;
            }
        }
    }

    &-btm {
        background: url(../img/common/dotted-bg.png) no-repeat;
        background-position: center;
        background-size: cover;
        padding-left: 2px;

        table {
            width: 100%;
            background: $color-white;
            border-left: 2px solid $color-yellow;

            tr {
                border-bottom: 1px solid rgba($color: #222, $alpha: .5);
                &:last-child{
                    border-bottom: 0;
                }
            }

            th,
            td {
                font-size: 1.6rem;
                line-height: calc(24 / 16);
                letter-spacing: .05em;
                display: block;
                @include mq("tablet") {
                    display: table-cell;
                }
            }

            th {
                padding: 20px 0 3px 20px;
                font-weight: 700;
                text-align: left;
                width: 190px;
                justify-content: center;
                @include mq("tablet") {
                    padding: 30px 0 30px 40px;
                    // vertical-align: middle; 
                }
            }

            td {
                padding: 0 20px 20px;
                @include mq("tablet") {
                    padding: 30px 20px 30px 0;
                }
                display: flex;
                gap: 16px;

                p {
                    line-height: calc(25 / 16);
                    @include mq("tablet") {
                        line-height: calc(27 / 16);
                    }
                }
            }
        }

    }
}