.resident {
    max-width: 1282px;
    width: 100%;
    margin-inline: auto 0;
    padding-inline: 20px 0;

    @include mq("desktop-lg") {
        padding-inline: 0;
    }

    @include mq("desktop-lg") {
        padding-inline: 0;
    }

    @media (min-width:1367px) {
        max-width: calc((100% / 2) + 600px);
    }

    .category {
        &-row {
            flex-direction: column;
            padding-block: 0 30px;
            margin-top: 21px;

            @include mq("tablet") {
                margin-top: 50.7px;
                flex-direction: row;
                padding-block: 0 59.7px;
            }
        }

        &-col {
            width: 100%;

            @include mq("tablet") {
                width: calc((100% / 2) - 1px);
            }

            &:hover {
                span {
                    opacity: 1;
                }

                .category-col--img img {
                    transform: scale(1);
                }

                @include mq("tablet") {
                    .category-col--img img {
                        transform: scale(1.2);
                    }
                }

                .category-col--icon img {
                    transform: scale(1);
                }
            }

            &--desc {
                width: fit-content;
                align-items: flex-start;
                justify-content: flex-start;
                top: 11px;
                left: 18px;
                transform: none;

                @include mq("tablet") {
                    top: 16px;
                    left: 25px;
                }

                p {
                    text-align: start;
                    font-weight: 500;
                    font-size: 2.2rem;
                    line-height: calc(31 / 22);
                    letter-spacing: .1em;

                    @include mq("tablet") {
                        font-size: 2.4rem;
                        line-height: calc(36 / 24);
                    }

                    @include mq("bootstrap-lg") {
                        font-size: 2.8rem;
                        line-height: calc(40 / 28);
                    }
                }

                span {
                    opacity: 1;
                    transition: opacity .5s ease;
                    font-weight: 500;
                    font-size: 1.6rem;
                    line-height: calc(24 / 16);
                    letter-spacing: .05rem;
                    margin-top: 11px;

                    @include mq("tablet") {
                        opacity: 0;
                        letter-spacing: .05rem;
                        margin-top: 27px;
                    }

                    @include mq("bootstrap-lg") {
                        font-size: 1.8rem;
                        line-height: calc(29 / 18);
                    }
                }
            }

            .span {
                background: none;
                border: 0;
                box-sizing: border-box;
                padding: 11px 12px;
                border: 1px solid $color-white;

                @include mq("tablet") {
                    padding: 15px 18px;
                    border: none;
                }

                @include mq("bootstrap-lg") {
                    padding: 28.9px 31px 28.5px;
                }


                position : relative;
                vertical-align: middle;

                &::before,
                &::after {
                    box-sizing: inherit;
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                }

                &::before {
                    border: 0 solid transparent;

                    @include mq("tablet") {
                        border: 2px solid transparent;
                    }
                }

                &::after {
                    border: 0 solid transparent;
                }

            }

            .circle {
                border-radius: 100%;
                box-shadow: none;

                &::before,
                &::after {
                    border-radius: 100%;
                }
            }

            &:hover {
                .span {
                    &::before {
                        border-top-color: $color-white; // Show borders
                        border-right-color: $color-white;
                        border-bottom-color: $color-white;

                        transition:
                            border-top-color 0.15s linear, // Stagger border appearances
                            border-right-color 0.15s linear 0.10s,
                            border-bottom-color 0.15s linear 0.20s;
                    }



                    &::after {
                        border-top: 2px solid transparent; // Shows border
                        border-left-width: 2px; // Solid edges, invisible borders
                        border-right-width: 2px; // Solid edges, invisible borders
                        transform: rotate(270deg); // Rotate around circle
                        transition:
                            transform 0.4s linear 0s,
                            border-left-width 0s linear 0.35s; // Solid edge post-rotation        }

                        @include mq("tablet") {
                            border-top: 2px solid $color-white; // Shows border
                        }
                    }

                }


            }

            &--icon {
                bottom: 20px;
                right: 20px;
                padding: 0;

                img {
                    width: 9px;
                    height: 11px;

                    @include mq("tablet") {
                        width: 14px;
                        height: 18px;
                    }
                }
            }

            &--img {
                overflow: hidden;
                background: linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .4), rgba(0, 0, 0, .2), rgba(0, 0, 0, 0));

                // &::after {
                // }

                img {
                    transition: all .5s ease;
                }
            }
        }
    }

    &-btn {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 0;
        margin-right: 20px;

        @include mq("tablet") {
            flex-direction: row;
            gap: 40px;
        }

        @include mq("bootstrap-lg") {
            margin-right: 83px;
        }

        &--img {
            width: 100%;
            // max-width: 580px;
            height: 67px;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            z-index: -1;
            transition: all .5s ease-in;

            @include mq("tablet") {
                height: 100%;
            }
        }

        &--link {
            transition: all .5s ease;
            color: $color-primary;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border: 1px solid rgba(34, 34, 34, .2);
            border-left: none;
            border-right: none;
            width: 100%;
            padding: 15.5px 0;
            position: relative;

            @include mq("tablet") {
                width: calc((100% / 2) - 20px);
                padding: 15px;
                border: 1px solid rgba(34, 34, 34, .2);
            }

            @include mq("bootstrap-lg") {
                padding: 31.5px 30px;
            }

            &:first-child {
                border-bottom: none;

                @include mq("tablet") {
                    border-bottom: 1px solid rgba(34, 34, 34, .2);
                }
            }

            span {
                font-size: 1.8rem;
                line-height: calc(26 / 18);
                letter-spacing: .1em;

                @include mq("tablet") {
                    letter-spacing: .05em;
                }

                @include mq("bootstrap-lg") {
                    font-size: 2rem;
                    line-height: calc(29 / 20);
                }
            }

            &:hover {
                color: $color-white;


                .resident-btn--icon {
                    background: $color-yellow;
                }

                .resident-btn--img {
                    opacity: 1;
                }
            }
        }

        &--icon {
            transition: all .5s ease;
            background: rgba(34, 34, 34, .1);
            border-radius: 50%;
            padding: 11.5px 12.5px;

            @include mq("tablet") {
                padding: 14px 15.5px;
            }

            img {
                width: 10px;
                height: 12px;

                @include mq("tablet") {
                    width: 14px;
                    height: 17px;
                }
            }
        }

        &--btm {
            margin-top: 0;
            margin-right: 20px;

            @include mq("tablet") {
                margin-top: 40px;
                margin-right: 0;
            }

            .resident-btn--link {
                border-bottom: 1px solid rgba(34, 34, 34, .2);
                border-top: 0;
                border-left: 0;
                border-right: 0;
                width: 100%;
                padding: 20px 0 22.5px 0;

                @include mq("tablet") {
                    border: 1px solid rgba(34, 34, 34, .2);
                    padding: 12.5px 30px 16.5px 29px;
                    width: 60%;
                }

                @include mq("bootstrap-lg") {
                    width: calc(50% - 61px);
                }

                &:hover {
                    color: $color-primary;
                }
            }
        }

        &--txt {
            img {
                max-width: 287px;
                margin-top: 5px;
            }
        }
    }
}